import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button, Col, InputGroup, Input, Row, DropdownToggle, InputGroupButtonDropdown, Collapse, Card, CardBody, CardFooter } from 'reactstrap';
import { FaFilter, FaSearch } from "react-icons/fa";

const ButtonsIndex = ({
  onSearch,
  onNew,
  moreFilters,
  notButtons,
  notSearch,
  aditionalButtons,
  styleSearch,
  titleSearch,
  disabledInputSearch,
  filters,
  updateData,
  onCleanFilters
}) => {
  const [textSearch, setTextSearch] = useState('');
  const refText = useRef();
  refText.current = textSearch
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(0);
  const [filtersSelected, setFiltersSelected] = useState(0);
  const [update, setUpdate] = useState(false);

  const handleInput = (e) => {
    setTextSearch(e.target.value);
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleApplyFilters();
    }
  }

  const handleClickSearch = useCallback(() => {
    handleApplyFilters();
  }, [onSearch, textSearch]);

  const handleFiltersSelected = () => {
    let count = 0;
    for (let i in filters) {
      if (!!filters[i]) {
        count++;
      }
    }
    if (!!textSearch) {
      count++;
    }

    return count;
  }

  const handleApplyFilters = () => {
    setFiltersApplied(handleFiltersSelected);
    onSearch(!!textSearch ? textSearch : '')
  }

  const handleCleanFilters = () => {
    setTextSearch('')
    if (onCleanFilters) {
      onCleanFilters()
    }
    setUpdate(!update);
  }

  useEffect(() => setFiltersApplied(handleFiltersSelected()), [])

  useEffect(() => setFiltersSelected(handleFiltersSelected()), [filters, textSearch])

  useEffect(() => handleApplyFilters(), [updateData, update])

  return (
      <>
          <div className="flex-space-between">
            <div>
              { !notButtons &&
                <>
                    {
                      onNew &&
                      <Button className="mb-2 mr-3" color="focus" onClick={onNew} >Novo</Button>
                    }
                    {aditionalButtons}
                </>
              }
            </div>
            <div>
              { !notSearch &&
                <>
                  <InputGroup style={styleSearch} className="mb-2">
                    <Input inputMode="search" id="inputSearch" disabled={disabledInputSearch} placeholder="Digite para buscar" title={titleSearch} value={textSearch} onInput={handleInput} onKeyDown={handleEnter} />
                    <InputGroupButtonDropdown addonType="append" isOpen={dropDownOpen} direction="down" toggle={() => {}}>
                      <Button dusk="search" color="light" onClick={handleClickSearch}>
                        <FaSearch color="var(--focus)" />
                      </Button>
                      {
                        moreFilters &&
                        <DropdownToggle dusk="filters" split color="light" onClick={() => setDropDownOpen(!dropDownOpen)}><FaFilter />&nbsp;{filtersApplied > 0 && `(${filtersApplied})`}&nbsp;</DropdownToggle>
                      }
                    </InputGroupButtonDropdown>
                  </InputGroup>
                </>
              }
            </div>
          </div>
          <div>
            <Collapse isOpen={dropDownOpen}>
              <Card className="card-shadow-focus border mb-2">
                <CardBody>
                  { moreFilters }
                </CardBody>
                <CardFooter>
                  <Row style={{margin: 0, width: '100%'}}>
                    <Col md={12}>
                      <Button id="btnApplyFilters" color="focus" outline onClick={handleApplyFilters} className="mr-2" style={{float: 'right'}}><FaSearch />&nbsp;Aplicar {filtersSelected > 0 && filtersSelected} {filtersSelected === 1 ? 'Filtro' : 'Filtros'}</Button>
                      <Button id="btnCleanFilters" color="light" onClick={handleCleanFilters} className="mr-2" style={{float: 'right'}}>Limpar Filtros</Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Collapse>
          </div>
          <br />
      </>
  )
}

export default ButtonsIndex;
