import { formatDate, formatDigitableLine, formatPhone, formatValue, isMobile } from 'nfutils'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Card, Col, Row } from 'reactstrap'
import QRCode from "react-qr-code";

import api from '../../../services/api'
import { setLoading } from '../../../reducers/AppConfig'
import { ErrorToast } from '../../../Layout/AppMain/Components/Toast'
import { optionsTypeReceipt } from '../../../services/defaultOptions';

const PaymentReceipt = ({ setLoading, history, match }) => {
    const { id } = match.params
    const [state, setState] = useState({
        customer: null,
        total: 0,
        due_date: null,
    })

    const handleBack = () => {
        history.push('/');
      }

    const getData = async () => {
        try {
          setLoading(true);
          let response = await api.get(`p/${id}/receipt`)
          setState({
            ...state,
            ...response.data,
          })
        } catch (e) {
          console.log(e)
          let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno, tente novamente mais tarde'
          ErrorToast({ placeholder: msg })
          handleBack()
        } finally {
          setLoading(false)
        }
    }

    useEffect(() => {
        if (typeof id !== 'undefined') {
          getData();
        }
    }, [id])

    return (
        <div style={{ height: '100vh', width: '100vw', position: 'relative' }}>
            <div className={!isMobile() ? 'w-50' : 'w-100'} style={{ position: 'absolute', left: 0, right: 0, top: 30, margin: '0 auto' }}>
                <Card body>
                    <Row className='w-100 receipt-header p-1'>
                        <Col md={5} sm={12} xs={12}>
                            <ul className="mb-2">
                                <li style={{ textTransform: 'uppercase' }}>{state.company && state.company.name}</li>
                                <li>{state.company && state.company.email}</li>
                                {(state.company && (state.company.phone)) &&
                                <li>{formatPhone({ value: state.company.phone })}</li>
                                }
                            </ul>
                        </Col>
                        <Col md={7} sm={12} xs={12}>
                            <Row>
                                <Col md={10} sm={12} xs={12}>
                                    <span className="mb-2">
                                        <h6><b>{optionsTypeReceipt[state.status] ? optionsTypeReceipt[state.status].label : ''} da conta</b></h6>
                                        gerado em {formatDate({ value: new Date(state.generated_at ? state.generated_at.replace(' ', 'T') : '' ), format: 'DD/MM/YYYY [às] HH:mm' })}
                                    </span>
                                </Col>
                                {
                                    (!isMobile() && state.receipt_url) &&
                                    <Col md={2} sm={12} xs={12}>
                                        <QRCode className="mt-2 float-right" value=
                                        {state.receipt_url} size={70} />
                                    </Col>
                                }
                            </Row>
                        </Col>
                    </Row>
                    <hr/>
                    <span className="pl-1 py-2"><b>Pagamento {state.status === 0 ? 'agendado' : 'efetuado'} por:</b> {state.company && state.company.name}</span>
                    <hr/>
                    <div className='pl-1 py-2'>
                        <div className="py-1"><b>Valor:</b> {formatValue({ value: state.total, currency: 'BRL' })}</div>
                        <div className="py-1"><b>Data do vencimento:</b> {state.due_date && formatDate({ value: new Date(`${state.due_date}T00:00:00`), location: 'Br', format: 'Date' })}</div>
                        {
                            state.status === 0
                            ?
                            <div className="py-1"><b>Data do agendamento:</b> {state.schedule_date && formatDate({ value: new Date(`${state.schedule_date}T00:00:00`), location: 'Br', format: 'Date' })}</div>
                            :
                            <div className="py-1"><b>Data do pagamento:</b> {state.payed_date && formatDate({ value: new Date(`${state.payed_date}T00:00:00`), location: 'Br', format: 'Date' })}</div>
                        }
                        {
                            state.digitable_line &&
                            <div className="py-1"><b>Linha digitável:</b> {state.digitable_line && formatDigitableLine(state.digitable_line)}</div>
                        }
                        {
                            state.copiaecola &&
                            <div className="py-1"><b>Copia e cola:</b> {state.copiaecola}</div>
                        }
                    </div>
                    <hr />
                    <span className='px-1 text-center' style={{ fontSize: 12 }}>Este documento e cobrança não possuem valor fiscal e são de responsabilidade única e exclusiva de <b style={{ textTransform: 'uppercase' }}>{ state.company && state.company.name }</b></span>
                    <hr />
                    <span className='px-1 text-center' style={{ fontSize: 12 }}>Cobrança intermediada por <a href="/">wfpay.com.br</a></span>
                </Card>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
    setLoading: (enable, text) => dispatch(setLoading(enable, text))
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReceipt)
