export const MenuNav = [
  {
    icon: "pe-7s-graph3",
    label: "Resumo",
    to: '/#/'
  },
  {
    icon: "pe-7s-users",
    label: "Clientes",
    to: "/#/clientes"
  },
  {
    icon: "pe-7s-cash",
    label: "Cobranças",
    content: [
      {
        label: "Todas",
        to: '/#/cobrancas'
      },
      {
        label: "Avulsas",
        to: '/#/cobrancas/avulsas'
      },
      {
        label: "Parcelamentos",
        to: '/#/parcelamentos'
      },
      {
        label: "Assinaturas",
        to: '/#/assinaturas'
      },
    ]
  },
  {
    icon: "pe-7s-portfolio",
    label: "Links de Pagamento",
    to: "/#/links-de-pagamentos"
  },
  {
    icon: "bi bi-search",
    label: "Cosultas Serasa",
    to: "/#/serasa/consultas"
  },
  {
    icon: "pe-7s-wallet",
    label: "Meu Dinheiro",
    content: [
      {
        label: "Extrato",
        to: "/#/extrato"
      },
      {
        label: "Transferências",
        to: "/#/transferencias"
      },
      {
        label: "Pague Contas",
        to: "/#/pagamentos"
      }
    ]
  },
  {
    icon: "pe-7s-hammer",
    label: "Negativação",
    to: "/#/negativacao"
  }
];

const getRoutes = (menu) => {
  let routes = {}
  for (let i in menu) {
    if (menu[i].to) {
      routes[menu[i].to] = menu[i].to;
    }
    if(menu[i].content && menu[i].content.length > 0) {
      routes = {
        ...routes,
        ...getRoutes(menu[i].content)
      }
    }
  }

  return routes
}

export const ListRoutes = () => getRoutes(MenuNav);
