import { isMobile } from 'nfutils'
import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'

const CustomTooltip = ({ input, target, tooltipText, textAlign, maxWidth }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      {input}
      {
        tooltipText &&
        <Tooltip style={{ textAlign, maxWidth }} placement="auto" target={target} isOpen={isOpen} toggle={toggle} dangerouslySetInnerHTML={{__html: tooltipText}} />
      }
    </>
  )
}

CustomTooltip.defaultProps = {
  textAlign: 'justify',
  maxWidth: isMobile() ? '80vw' : '40vw',
}

export default CustomTooltip
