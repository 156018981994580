import { formatCpfCnpj, formatPhone } from 'nfutils'
import React from 'react'
import { Col, Row } from 'reactstrap'

const DataBanner = ({ state }) => {

    return (
        <div className={`modal-body data-company bg-${state.company_settings ? state.company_settings.custom_bg_color : 'focus'} text-${state.company_settings ? state.company_settings.custom_text_color : 'white'}`}>
            <Row>
            {
                    (state.company_settings && state.company_settings.with_brand) &&
                    <Col md={3} sm={12} xs={12} style={{ backgroundColor:  state.company_settings.custom_img_bg_hex_color, padding: 0 }}>
                        <div className="w-100 he-100 d-flex justify-content-center align-items-center" style={{ padding: 16 }}>
                            <div className="w-100 he-100 d-flex align-items-center justify-content-center" style={{ position: 'relative' }}>
                                <img src={state.company_settings.custom_img_url} style={{ maxWidth: 170, height: 140 }} alt="Sua marca" />
                            </div>
                        </div>
                    </Col>
                }
                <Col>
                    <div style={{ padding: '1em' }}>
                        <h4 style={{ marginBottom: 0 }}>{state.name}</h4>
                        <p>{formatCpfCnpj({ value: state.cpfcnpj })}</p>
                        <small>
                            {state.email}
                            <br />
                            {formatPhone({ value: (state.phone ? state.phone : state.cellphone) })}
                            <br />
                            {
                                state.type === 2 &&
                                <>
                                    {`${state.address}, ${state.number}, ${state.complement ? `${state.complement}, ` : ''}${state.neighborhood}`}
                                    <br />
                                    {`CEP: ${state.cep}`}
                                    <br />
                                </>
                            }
                            {state.city ? `${state.city.name} - ${state.state.sigla}` : ''}
                        </small>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

DataBanner.defaultProps = {
    state: {}
}

export default DataBanner
