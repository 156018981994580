import React from 'react';
import Select, { createFilter } from 'react-select';
import { FormFeedback } from 'reactstrap';

const ReactSelect = ({placeholder, onChange, className, value, noOptionsMessage, options, disabled, title, name, innerRef, invalid, invalidMsg, prefix, clearable, style, ...rest}) => {

  const customStyles = {
    container: (base) => ({
        ...base,
        ...style.container
    }),
    control: (base, state) => ({
      ...base,
      ...style.control,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ?
        '#ddd' : !invalid ?
        '#ddd' : 'red',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ?
          '#ddd' : !invalid ?
          '#ddd' : 'red'
      }
    })
  }

  const throwKeyHomeEnd = (e) => {
    if (e.key === 'End') {
      e.preventDefault();
      e.target.selectionEnd = e.target.value.length;
      if (!e.shiftKey) {
        e.target.selectionStart = e.target.value.length;
      }
    } else if(e.key === 'Home') {
      e.preventDefault();
      e.target.selectionStart = 0;
      if (!e.shiftKey) {
        e.target.selectionEnd = 0;
      }
    }
  }

  return (
    <>
      <Select
        filterOption={createFilter({
          matchFrom: 'any',
          stringify: option => `${option.label}`
        })}
        classNamePrefix={prefix}
        isClearable={clearable}
        className={className}
        placeholder={placeholder}
        closeMenuOnSelect={true}
        options={options}
        value={value}
        noOptionsMessage={noOptionsMessage}
        onChange={onChange}
        isDisabled={disabled}
        title={title}
        name={name}
        ref={innerRef}
        styles={customStyles}
        onKeyDown={throwKeyHomeEnd}
        {...rest}
      />
      <FormFeedback style={{ display: invalid ? 'block': 'none' }}>{invalidMsg}</FormFeedback>
    </>
  )
}

ReactSelect.defaultProps = {
  className: null,
  placeholder: 'Selecione...',
  options: [],
  value: null,
  noOptionsMessage: () => 'Nada Encontrado',
  onChange: null,
  disabled: false,
  name: null,
  innerRef: () => {},
  invalid: false,
  invalidMsg: 'Campo inválido',
  prefix: null,
  clearable: false,
  style: {
    container: {},
    control: {},
  }
}

export default ReactSelect;
