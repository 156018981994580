import { onlyNumber } from "nfutils";
import api from "./api";

const buscaCep = (cep) => {
  let cepform = onlyNumber(cep);
  let dados = {
    neighborhood: '',
    address: '',
    city: '',
    uf: '',
  }

  return new Promise((resolve, reject) => {
    api.get('consultations/cep?cep='+cepform)
    .then(function (response) {
      if (typeof response.data.errors === 'undefined') {
        dados.neighborhood = response.data.bairro;
        dados.address = response.data.logradouro;
        dados.city = response.data.localidade;
        dados.city_id = response.data.localidade_ibge
        dados.uf = response.data.uf;
        dados.state_id = response.data.uf_ibge;
      }

      resolve(dados);
    }, error => {
      reject(dados);
    });
  });
}

export default buscaCep;
