import React, { useCallback } from 'react'
import { Row, Col, FormGroup, Label, Input, Button, FormFeedback } from 'reactstrap'
import PageContent from '../../Layout/AppMain/PageContent'
import InputCurrency from '../../Layout/AppMain/Components/InputCurrency'
import { useState } from 'react'
import { dateNow, formatCpfCnpj, formatDate, formatValue } from 'nfutils';
import LogoWFPay from "../../assets/utils/images/logo-inverse.png"
import ProcuraBoleto from "../../assets/utils/images/procura-boleto.png"
import { setLoading } from '../../reducers/AppConfig'
import api from '../../services/api';
import DataTable from '../../Layout/AppMain/Components/DataTable'
import { FaArrowLeft, FaExternalLinkAlt, FaRegCheckCircle, FaRegClock, FaRegMoneyBillAlt, FaRegTimesCircle, FaSearch } from 'react-icons/fa'
import CustomTooltip from '../../Layout/AppMain/Components/CustomTooltip'
import { MdBlock, MdOutlineCreditCardOff } from 'react-icons/md'

function Index() {
    const [update, setUpdate] = useState(false);
    const [itens, setItens] = useState([])
    const [pageCount, setPageCount] = useState(0);
    const [totalItens, setTotalItens] = useState(0);
    const [totalItensFilter, setTotalItensFilter] = useState(0);
    const [showDataTable, setShowDataTable] = useState(false);
    const [invalid, setInvalid] = useState({});
    const [state, setState] = useState({
        cpfcnpj: '',
        charge_value: ''
    })

    const handleChange = (prop, value) => {
        setState({
            ...state,
            [prop]: prop === 'cpfcnpj' ? formatCpfCnpj({value: value}) : value
        })
    }

    const handleSubmit = () => {
        if(handleValidate()) return
        setUpdate(!update)
        setShowDataTable(true)
    }

    const handleBack = (clearFilters = false) => {
        if(clearFilters) {
            setState({
                cpfcnpj: '',
                charge_value: ''
            })
        }
        setShowDataTable(false)
    }

    const handleValidate = () => {
        let invalid = {}

        if(!state.cpfcnpj) {
            invalid.cpfcnpj = true
        }

        if(!state.charge_value) {
            invalid.charge_value = true
        }

        setInvalid(invalid)

        return Object.keys(invalid).length !== 0
    }

    const getData = useCallback(({formData, pageSize, pageIndex}) => {
        setLoading(true);
        api.get(`i`, {
          params: {
            cpfcnpj: formData.cpfcnpj ? formData.cpfcnpj : '',
            charge_value: formData.charge_value ? formData.charge_value : '',
            limit: pageSize,
            offset: pageIndex,
          }
        }).then(response => {
            const data = response.data.data.map((item, key) => {
                return {
                    ...item,
                    value: item.value ?  <b className="text-focus">{formatValue({ value: item.value, currency: 'BRL' })}</b>  : '',
                    due_date: item.due_date ? formatDate({ value: new Date(`${item.date}T00:00:00`), location: 'Br', format: 'Date' }) : '',
                    status: ([1, 2, 4, 5].includes(item.status) &&
                    <>
                        {
                            item.status === 1 &&
                            <>
                                <CustomTooltip input={<FaRegCheckCircle id={`charge_${key}_icon`} size={15} color="var(--success)" />} target={`charge_${key}_icon`} textAlign="center" tooltipText="Paga" />
                                &nbsp;&nbsp;<b><span style={{color: 'var(--success)'}}>Paga</span></b>
                            </>
                        }
                        {   item.status === 2 &&
                            <>
                                <CustomTooltip input={<FaRegMoneyBillAlt id={`charge_${key}_icon`} size={15} color="var(--success)" />} target={`charge_${key}_icon`} textAlign="center" tooltipText="Recebida em dinheiro" />
                                &nbsp;&nbsp;<b><span style={{color: 'var(--success)'}}>Recebida em dinheiro</span></b>
                            </>
                        }
                        {
                            item.status === 4 &&
                            <>
                                <CustomTooltip input={<MdOutlineCreditCardOff id={`charge_${key}_icon`} size={15} color="var(--danger)" />} target={`charge_${key}_icon`} textAlign="center" tooltipText="Chargeback solicitado pelo cliente" />
                                &nbsp;&nbsp;<b><span style={{color: 'var(--danger)'}}>Chargeback</span></b>
                            </>
                        }
                        {
                            item.status === 5 &&
                            <>
                                <CustomTooltip input={<MdBlock id={`charge_${key}_icon`} size={15} color="var(--danger)" />} target={`charge_${key}_icon`} textAlign="center" tooltipText="Cancelada manualmente" />
                                &nbsp;&nbsp;<b><span style={{color: 'var(--danger)'}}>Cancelada</span></b>
                            </>
                        }
                    </>)
                    ||
                    ((item.status === 0 && dateNow(null, 'YYYY-MM-DD') <= item.due_date) &&
                    <>
                        <CustomTooltip input={<FaRegClock id={`charge_${key}_icon`} size={15} color="var(--warning)" />} target={`charge_${key}_icon`} textAlign="center" tooltipText="Aguardando pagamento" />
                        &nbsp;&nbsp;<b><span style={{color: 'var(--warning)'}}>Aguardando pagamento</span></b>
                    </>
                    )
                    ||
                    ((item.status === 0 && dateNow(null, 'YYYY-MM-DD') > item.due_date) &&
                    <>
                        <CustomTooltip input={<FaRegTimesCircle id={`charge_${key}_icon`} size={15} color="var(--danger)" />} target={`charge_${key}_icon`} textAlign="center" tooltipText="Vencida" />
                        &nbsp;&nbsp;<b><span style={{color: 'var(--danger)'}}>Vencida</span></b>
                    </>
                    )
                    ||
                    "",
                    actions: <a href={item.url} target='_blank' rel='noreferrer'>
                        <FaExternalLinkAlt size={15} color="var(--focus)" />
                    </a>,
                }
            })
            setItens(data)
            setPageCount(Math.ceil(response.data.total / pageSize))
            setTotalItens(response.data.total)
            setTotalItensFilter(response.data.totalFiltered)
            setShowDataTable(true)
        })
        .catch(error => {
            console.log(error)
        })
        .finally(() => {
            setLoading(false);
        })
    }, [update])

  return (
    <>
        <Row style={{ backgroundColor: "var(--primary)"}}>
            <Col className="d-flex justify-content-center">
                <a href='https://wfpay.com.br' target='_blank' rel='noreferrer'>
                    <img style={{width: '125px'}} className="p-2" src={LogoWFPay} alt='Logo WFPay - Gestão financeira'/>
                </a>
            </Col>
        </Row>
        <Row className='py-4 d-flex justify-content-center'>
            <Col md={8}>
                <PageContent>
                    {!showDataTable ?
                        <>
                            <div className='d-flex align-items-center flex-column'>
                                <img style={{width: '125px'}} className="p-2" src={ProcuraBoleto} alt='Imagem de uma bussola em cima de uma fatura'/>
                                <h4 className='my-4'>Consulte as faturas de forma fácil e rápida!</h4>
                            </div>
                            <div className='my-2 mx-2'>
                                <FormGroup>
                                    <Label for="cpfcnpj">CPF ou CNPJ <span className="text-danger">*</span> </Label>
                                    <Input id="cpfcnpj" size='lg' onChange={e => handleChange("cpfcnpj", e.target.value)} value={state.cpfcnpj} placeholder='Seu CPF ou CNPJ' invalid={invalid.cpfcnpj}/>
                                    <FormFeedback>Campo obrigatório</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="charge_value">Valor do boleto <span className="text-danger">*</span> </Label>
                                    <InputCurrency id="charge_value" prefix={'R$'} size='lg' onChange={e => handleChange("charge_value", e)} value={state.charge_value} placeholder={'Valor do boleto'} invalid={invalid.charge_value} invalidMsg={"Campo obrigatório"} />
                                </FormGroup>
                                <Button className='btn-block mt-4' color='focus' size='lg' onClick={handleSubmit}>Consultar</Button>
                            </div>
                        </>
                    :
                        <>
                            <Row className='pb-3'>
                                <Col className='d-flex flex-row justify-content-between align-items-center'>
                                    <Button outline color='primary' onClick={() => handleBack()}>
                                        <FaArrowLeft size={15} className='mr-2' style={{cursor: 'pointer'}}/>
                                        Voltar
                                    </Button>
                                    <div>
                                        <h4 className='mb-0'>
                                            Faturas
                                        </h4>
                                    </div>
                                    <Button outline color='primary' onClick={() => handleBack(true)}>
                                        Pesquisar Novamente
                                        <FaSearch size={15} className='ml-2' style={{cursor: 'pointer'}}/>
                                    </Button>
                                </Col>
                            </Row>
                            <DataTable
                                columns={[
                                    {Header: "Fornecedor", accessor: "company.name", width: '35%'},
                                    {Header: "Valor", accessor: "value"},
                                    {Header: "Data de vencimento", accessor: "due_date"},
                                    {Header: "Situação", accessor: "status"},
                                    {Header: "Ações", accessor: "actions"},
                                ]}
                                showFilter={false}
                                data={itens}
                                formData={state}
                                updateData={update}
                                fetchData={getData}
                                pageCount={pageCount}
                                totalReg={totalItens}
                                totalRegFilter={totalItensFilter}
                                pageSize={10}
                                notRowCheckbox={true}
                            />
                        </>
                    }
                </PageContent>
                <br />
                <Row>
                    <Col className='text-center'>
                        <span className='text-muted'>Cobranças intermediadas por</span> <a href='https://wfpay.com.br' target='_blank' rel='noreferrer'><b>WFPay</b></a>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>
  )
}

export default Index
