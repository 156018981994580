import React, { useEffect, useRef, useState } from "react";
import { FormFeedback, InputGroup, InputGroupAddon } from "reactstrap";
import { NumberFormatBase } from "react-number-format";

const InputCurrency = ({
    className,
    placeholder,
    decimalSeparator,
    allowNegativeValue,
    showCurrencySimbol,
    suffix,
    thousandSeparator,
    value,
    onValueChange,
    invalid,
    invalidMsg,
    scale,
    disabled,
    valid,
    onBlur,
    onChange,
    onKeyUp,
    customSymbol,
    ...rest
}) => {
    const inputRef = useRef();
    const [isNegative, setIsNegative] = useState(false);

    if (invalid) {
        className = `${className} is-invalid`;
    } else if (valid) {
        className = `${className} is-valid`;
    }

    const handleChange = ({floatValue}) => {
        if(!floatValue) return onChange(0);
        if(allowNegativeValue && isNegative && floatValue > 0) {
            floatValue = floatValue * -1;
        }

        const newValue = parseFloat(floatValue) / 100;
        onChange(newValue || 0);
    };

    const _onPaste = (e) => {
        const value = e.clipboardData.getData('text/plain');
        if(value.includes('-')) {
            setIsNegative(true);
        }
    };

    const handleKeyDown = (e) => {
        if (allowNegativeValue && e.key === "-") {
            setIsNegative(!isNegative);
        }
    };

    function currencyFormatter(value) {
        if (!value) return "";

        if(allowNegativeValue && isNegative && value > 0) {
            value = value * -1;
        }

        const formattedValue = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
            currencyDisplay: "narrowSymbol",
            maximumFractionDigits: scale,
        }).format(value / 100);

        const amount = formattedValue?.replace("R$", "").trim();

        return `${amount}`;
    }
    return (
        <InputGroup>
            {showCurrencySimbol && (
                <InputGroupAddon addonType="prepend">{customSymbol ? customSymbol : 'R$'}</InputGroupAddon>
            )}
            <NumberFormatBase
                className={`form-control${className ? ` ${className}` : ""}`}
                placeholder={placeholder}
                decimalSeparator={decimalSeparator}
                allowNegativeValue={allowNegativeValue}
                groupSeparator={'.'}
                value={Number(value * 100) || ""}
                onValueChange={handleChange}
                onBlur={onBlur}
                format={currencyFormatter}
                onKeyDownCapture={handleKeyDown}
                onPaste={_onPaste}
                getInputRef={inputRef}
                onKeyUp={onKeyUp}
                disabled={disabled}
                {...rest}
            />
            {suffix && (
                <InputGroupAddon addonType="append">{suffix}</InputGroupAddon>
            )}
            <FormFeedback>{invalidMsg || "Campo inválido"}</FormFeedback>
        </InputGroup>
    );
};

InputCurrency.defaultProps = {
    className: "",
    placeholder: "0,00",
    decimalSeparator: ",",
    showCurrencySimbol: true,
    allowNegativeValue: false,
    thousandSeparator: ".",
    scale: 2,
    value: "",
    prefix: "",
    onValueChange: () => {},
    onBlur: () => {},
    onChange: () => {},
    onKeyUp: () => {},
    onKeyDown: () => {},
};

export default InputCurrency;
