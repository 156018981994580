import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setLoading } from "../../../reducers/AppConfig";

import { Col, Row, Button, Form, FormGroup, Input, FormFeedback, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../../Layout/AppMain/Components/Toast";
import api from "../../../services/api";
import { dateNow } from 'nfutils';
// Layout

const ForgotPasswordBoxed = ({ setLoading, history, isAuthenticated }) => {
    const [ email, setEmail ] = useState('')
    const [ validation, setValidation ] = useState(false)

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
        handleSubmit();
        }
    }

    const handleSubmit = async () => {
        if (!email || !email.trim()) {
        setValidation(true)
        return false
        }

        setValidation(false)
        setLoading(true)
        try {
        await api.post('forgot', { email })
        SuccessToast({ placeholder: 'Solicitação realizada com sucesso! Por favor, verifique sua caixa de entrada (Caso não encontre, verifique também o spam).' })
        setEmail('')
        } catch (e) {
        console.log(e)
        let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar solicitar a redifinição de senha, tente novamente mais tarde'
        ErrorToast({ placeholder: msg })
        } finally {
        setLoading(false)
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
        history.push('/');
        }
    }, [isAuthenticated])
    return (
        <>
          <div className="min-vh-100 bg-plum-plate bg-animation">
            <div className="d-flex h-100 justify-content-center align-items-center">
              <Col md="6" className="mx-auto app-login-box">
                <div className="app-logo-inverse mx-auto mb-3" style={{ height: '8rem' }} />
                <div className="modal-dialog w-100">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="h5 modal-title">
                        Esqueceu sua senha?
                        <h6 className="mt-1 mb-0 opacity-8">
                          <span>Preencha o seu e-mail abaixo que iremos enviar um link para que você possa redefinir sua senha!</span>
                        </h6>
                      </div>
                    </div>
                    <div className="modal-body">
                      <div>
                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="email">E-mail</Label>
                                    <Input inputMode="email" invalid={validation} id="email" type="email" placeholder="Digite seu e-mail aqui..." value={email} onChange={({target: { value }}) => setEmail(value)} onKeyUp={handleKeyUp} />
                                    <FormFeedback>Informe um e-mail válido</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                      </div>
                      <div className="divider" />
                      <h6 className="mb-0">
                        <Link className="text-primary" to="/login">
                            Entrar com uma conta existente
                        </Link>
                      </h6>
                    </div>
                    <div className="modal-footer clearfix">
                      <div className="float-right">
                        <Button type="button" id="forgot_password" color="primary" size="lg" onClick={handleSubmit}>
                            Recuperar Senha
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center text-white opacity-8 mt-3">
                    Copyright &copy; WFPay Gestão Financeira {dateNow(null, 'YYYY')}
                </div>
              </Col>
            </div>
          </div>
        </>
      );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.AppConfig.isAuthenticated,
})

const mapDispatchToProps = (dispatch) => ({
  setLoading: (enable, text = null) => dispatch(setLoading(enable, text))
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordBoxed);
