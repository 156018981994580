import React from 'react'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import { useHotkeys } from 'react-hotkeys-hook';

import './style.css';

const ModalDialog = ({ images, isOpen, setIsOpen, currentImg, setCurrentImg }) => {
    const handlePrevious = () => {
        let currency = currentImg - 1;
        if (currency >= 0) {
            setCurrentImg(currency)
        }
    }

    const handleNext = () => {
        let currency = currentImg + 1;
        if (currency <= (images.length - 1)) {
            setCurrentImg(currency)
        }
    }

    useHotkeys('esc', () => setIsOpen(false), {filter: () => isOpen}, [isOpen, setIsOpen]);
    useHotkeys('left', handlePrevious, {filter: () => isOpen}, [isOpen, handlePrevious]);
    useHotkeys('right', handleNext, {filter: () => isOpen}, [isOpen, handleNext]);

    return (
        <dialog className="dialog dialog-modal-main" open={isOpen} onClick={() => setIsOpen(false)}>
            <div className="dialog-modal">
                <div className="dialog-content">
                    <div className="dialog-modal-main-files">
                        <div onClick={e => e.stopPropagation()}>
                            {
                                currentImg > 0 &&
                                <FaArrowLeft className="arrow-prev" onClick={handlePrevious} />
                            }
                        </div>
                        <div className="dialog-modal-main-canvas" onClick={e => e.stopPropagation()}>
                            {
                                images.map((img, key) => {
                                    return <div key={key} className={`dialog-modal-main-img ${currentImg === key ? '' : 'hide'}`} data-img-index={key} style={{ cursor: 'pointer' }} onClick={() => {
                                        setCurrentImg(key);
                                        setIsOpen(true);
                                    }}>
                                        <div style={{ backgroundImage: `url(${img.url})` }} className="dialog-modal-main-img-cover" />
                                    </div>
                                })
                            }
                        </div>
                        <div onClick={e => e.stopPropagation()}>
                            {
                                currentImg < (images.length - 1) &&
                                <FaArrowRight className="arrow-next" onClick={handleNext} />
                            }
                        </div>
                    </div>
                    <div className="dialog-circles" onClick={e => e.stopPropagation()}>
                        {
                            images.map((_, key) => <div key={key} className={`dialog-circle modal-main-img ${key === currentImg ? 'selected' : ''}`} onClick={() => setCurrentImg(key)}>
                            {(key + 1)}
                          </div>)
                        }
                    </div>
                </div>
            </div>
        </dialog>
    )
}

ModalDialog.defaultProps = {
    images: [],
    isOpen: false,
    setIsOpen: () => {},
    currentImg: 0,
    setCurrentImg: () => {}
}

export default ModalDialog
