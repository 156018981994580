import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useTable, usePagination, useRowSelect } from "react-table";
import { Pagination, PaginationItem, PaginationLink, Table, Label } from "reactstrap";
import ButtonsIndex from "../ButtonsIndex";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

const DataTable = ({
  columns,
  data,
  fetchData,
  totalReg: controlledTotalReg,
  totalRegFilter: controlledTotalRegFilter,
  onSelectItem,
  showFilter,
  moreFilters,
  updateData,
  onNew,
  notRowCheckbox,
  notButtons,
  notSearch,
  showPagination,
  filterDate,
  aditionalButtons,
  styleSearch,
  formData,
  ignoreCells,
  pageSize: controlledPageSize,
  titleSearch,
  disabledInputSearch,
  onCleanFilters
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    totalReg,
    totalRegFilter,
    // Get the state from the instance
    state: { selectedRowIds, pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: controlledPageSize }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: Math.ceil((controlledTotalReg !== controlledTotalRegFilter ? controlledTotalRegFilter : controlledTotalReg) / controlledPageSize),
      totalReg: controlledTotalReg,
      totalRegFilter: controlledTotalRegFilter
    },
    usePagination,
    useRowSelect,
    hooks => {
      !notRowCheckbox && hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} title="Selecionar todos" />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} title="Selecionar" />
            </div>
          ),
          width: "5%"
        },
        ...columns,
      ])
    }
  );

  const [filter, setFilter] = useState('');

  const start_page = useMemo(() => {
    return (pageIndex*pageSize)+1;
  }, [pageSize, pageIndex]);

  const end_page = useMemo(() => {
    return (pageIndex*pageSize)+data.length;
  }, [data, pageSize, pageIndex]);

  useEffect(() => {
    fetchData({ pageIndex, pageSize, filter, formData });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, pageIndex, pageSize]);

  useEffect(() => {
    if (onSelectItem !== undefined) {
      const dataIds = Object.keys(selectedRowIds).map((indice) => {
        return data[indice].id;
      })
      onSelectItem(dataIds);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds]);

  const handleSearch = (textSearch) => {
    gotoPage(0);
    setFilter(`filter=${textSearch}`);
    fetchData({ pageIndex, pageSize, filter: `filter=${textSearch}`, formData });
  }

  return (
    <>
      {showFilter && <ButtonsIndex disabledInputSearch={disabledInputSearch} onNew={onNew} titleSearch={titleSearch} onSearch={handleSearch} moreFilters={moreFilters} notButtons={notButtons} notSearch={notSearch} styleSearch={styleSearch}  filterDate={filterDate} aditionalButtons={aditionalButtons} filters={formData} onCleanFilters={onCleanFilters} updateData={updateData}/>}
      <Table {...getTableProps()}  responsive hover>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} width={column.width}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            if (typeof row.original.onClick != 'undefined') {
              row.onClick = row.original.onClick;
              row.style = {cursor: "pointer"};
            } else {
              row.onClick = () => {};
              row.style = {};
            }
            prepareRow(row)
            return (
              <tr onClick={row.onClick} style={row.style} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  if (['selection', 'actions', ...ignoreCells].includes(cell.column.id)) {
                    return <td onClick={e => e.stopPropagation()} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  } else {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  }
                })}
              </tr>
            )
          })}
          <tr hidden={data.length !== 0} style={{textAlign: 'center'}}>
            <td colSpan={columns.length+1}><h6 id='no_registers' className="mt-2">Nenhum registro encontrado</h6></td>
          </tr>
        </tbody>
      </Table>
      {
        (totalRegFilter !== 0 && showPagination) &&
        <div className="flex-space-between">
          <div>
            <Label>
              Mostrando {start_page} até {end_page} de {totalReg !== totalRegFilter ? totalRegFilter : totalReg} registros {totalReg !== totalRegFilter ? `(Filtrado de um total de ${totalReg} registros)` : ''}
            </Label>
          </div>
          <div className="d-flex">
            <Pagination listClassName="justify-content-end">
              <PaginationItem disabled={!canPreviousPage}>
                <PaginationLink first onClick={() => gotoPage(0)} />
              </PaginationItem>
              <PaginationItem disabled={!canPreviousPage}>
                <PaginationLink previous onClick={() => previousPage()} />
              </PaginationItem>
              {
                pageIndex-2 >= 0 &&
                <PaginationItem>
                  <PaginationLink onClick={() => gotoPage(pageIndex-2)}>
                    {pageIndex-1}
                  </PaginationLink>
                </PaginationItem>
              }
              {
              (pageIndex-1 >= 0) &&
              <PaginationItem>
                <PaginationLink onClick={() => gotoPage(pageIndex-1)}>
                  {pageIndex}
                </PaginationLink>
              </PaginationItem>
              }
              <PaginationItem active>
                <PaginationLink onClick={() => gotoPage(pageIndex)}>
                  {pageIndex+1}
                </PaginationLink>
              </PaginationItem>
              {
                pageIndex+2 <= pageCount &&
                <PaginationItem>
                  <PaginationLink onClick={() => gotoPage(pageIndex+1)}>
                    {pageIndex+2}
                  </PaginationLink>
                </PaginationItem>
              }
              {
                pageIndex+3 <= pageCount &&
                <PaginationItem>
                  <PaginationLink onClick={() => gotoPage(pageIndex+2)}>
                    {pageIndex+3}
                  </PaginationLink>
                </PaginationItem>
              }

              <PaginationItem disabled={!canNextPage}>
                <PaginationLink next onClick={() => nextPage()} />
              </PaginationItem>
              <PaginationItem disabled={!canNextPage}>
                <PaginationLink last onClick={() => gotoPage(pageCount - 1)} />
              </PaginationItem>
            </Pagination>
          </div>
        </div>
      }
    </>
  )
}

DataTable.defaultProps = {
  notRowCheckbox: false,
  notButtons: false,
  notSearch: false,
  showPagination: true,
  filterDate: false,
  aditionalButtons: '',
  styleSearch: {},
  formData: [],
  ignoreCells: [],
  pageSize: 50,
  disabledInputSearch: false
}

DataTable.propTypes = {
  showPagination: PropTypes.bool
}

export default DataTable;
