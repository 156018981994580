import { useRef, useState } from 'react'
import InputMask from '../../../Layout/AppMain/Components/InputMask'
import { useEffect } from 'react';
import { FormFeedback, InputGroup } from 'reactstrap';
import { CardIcons } from '../../../Layout/AppMain/Components/CardIcons/Index';

function CardInputToken({
    credencial,
    sandbox = false,
    dataCallback,
    ...props
}) {
    const tokenInputRef = useRef(null);
    const bandeiraInputRef = useRef(null);
    const cardNumberRef = useRef(null);
    const [cardInfo, setCardInfo] = useState({
        bandeira: null,
        token: null
    });

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://s3-sa-east-1.amazonaws.com/widgets.superlogica.net/embed.js";
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {
            window.superlogica.require("pjbank");
            window.superlogica.pjbank("checkout_transparente", credencial, sandbox);
        }

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    useEffect(() => {
        const cardNumber = cardNumberRef?.current?.props?.value;
        if(cardNumber < 6){
            setCardInfo({bandeira: null, token: null});
        }
    }, [cardNumberRef?.current?.props?.value]);

    useEffect(() => {
        const token = tokenInputRef.current?.value;
        const bandeira = bandeiraInputRef.current?.value === 'jbc' ? 'jcb' : bandeiraInputRef.current?.value;
        if(!token || !bandeira) return;
        setCardInfo({bandeira, token});
        if(dataCallback){
            dataCallback({bandeira, token});
        }
    }, [tokenInputRef.current?.value, bandeiraInputRef.current?.value]);

    return (
        <>
            <InputGroup className='d-flex align-items-center'>
                <InputMask
                    {...props}
                    inputRef={cardNumberRef}
                    className="pjbank-cartao"
                />
                {cardInfo && cardInfo.bandeira && (
                    <img
                        className='px-2'
                        alt='Imagem da logomarca da gestora do cartão'
                        src={cardInfo ? CardIcons?.find(card => card.name === cardInfo.bandeira)?.src : ''}
                        style={{ width: 80, height: 80, pointerEvents: 'none' }}
                    />
                )}
                <FormFeedback>Informe um cartão válido</FormFeedback>
            </InputGroup>
            <input
                type="hidden"
                name="pjbank-token"
                className="pjbank-token"
                ref={tokenInputRef}
            />
            <input
                ref={bandeiraInputRef}
                type="hidden"
                id="bandeira"
                name="pjbank-cartao-bandeira"
                className="pjbank-cartao-bandeira"
            />
        </>

    )
}

export default CardInputToken
