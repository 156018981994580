import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async-creatable";
import { FormFeedback } from "reactstrap";
import api from "../../../../services/api";

const AutoComplete = ({
  urlFind,
  disabled,
  value,
  placeholder,
  optionLabel,
  showCreate,
  positionCreate,
  onChange,
  onCreateOption,
  isFocused,
  autoFocus,
  defaultOptions,
  aditionalFilters,
  imgLabel,
  invalid,
  invalidMsg,
  classNamePrefix,
  styles,
  ...rest
}) => {
  const [isBusy, setIsBusy] = useState(false);
  const [valueSelected, setValueSelected] = useState(null);
  const [innerRef, setInnerRef] = useState(null);
  let cancelToken;

  const customStyles = {
    valueContainer: (base, state) => ({
      ...base,
      ...styles.valueContainer
    }),
    control: (base, state) => ({
      ...base,
      ...styles.control,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ?
        '#ddd' : !invalid ?
        '#ddd' : 'red',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ?
          '#ddd' : !invalid ?
          '#ddd' : 'red'
      }
    })
  }

  const isValidNewOption = (inputValue) => {
    return showCreate && inputValue !== '';
  }

  const createOptionPosition = () => {
    return ['first', 'last'].indexOf(positionCreate) !== -1 ? positionCreate : 'last';
  }

  const formatCreateLabel = (inputValue) => {
    return `Cadastrar "${inputValue}"`;
  }

  const noOptionsMessage = () => {
    return 'Nada encontrado';
  }

  const throwKeyHomeEnd = (e) => {
    if (e.key === 'End') {
      e.preventDefault();
      e.target.selectionEnd = e.target.value.length;
      if (!e.shiftKey) {
        e.target.selectionStart = e.target.value.length;
      }
    } else if(e.key === 'Home') {
      e.preventDefault();
      e.target.selectionStart = 0;
      if (!e.shiftKey) {
        e.target.selectionEnd = 0;
      }
    }
  }

  const handleChange = (v) => {
    setValueSelected(v);
    if (typeof onChange !== 'undefined' && typeof onChange === 'function') {
      onChange(v);
    }
  }

  const handleCreateOption = (inputValue) => {
    if (typeof onCreateOption !== 'undefined' && typeof onCreateOption === 'function') {
      onCreateOption(inputValue);
    }
  }

  const handleRef = (ref) => {
    setInnerRef(ref);
  }

  const loadOptions = async (inputValue) => {
    setIsBusy(true);
    if (typeof cancelToken !== 'undefined') {
      cancelToken.cancel();
    }

    let filters = '';
    Object.keys(aditionalFilters).forEach(function(filter) {
      filters = `${filters}&${filter}=${aditionalFilters[filter]}`
    });

    cancelToken = api.CancelToken.source();
    try {
      const response = await api.get(`${urlFind}?filter=${inputValue}${filters}`,{cancelToken: cancelToken.token});
      let itens = response.data.data
      .filter((item) => {
        if (typeof item.id === 'undefined' || typeof item[optionLabel] === 'undefined' || (imgLabel && typeof item[imgLabel] === 'undefined')) {
          return false;
        }

        return true;
      })
      .map((item) => {
        return {
          value: item.id,
          label: !imgLabel ? <span dangerouslySetInnerHTML={{__html: item[optionLabel]}} /> : <><img src={item[imgLabel]} alt="Icon img" style={{ height: 30 }} /> {item[optionLabel]}</>,
          data: item
        }
      });

      setIsBusy(false);
      return itens;
    } catch (error) {}

    setIsBusy(false);
    return [];
  }

  useEffect(() => {
    if (innerRef !== null && autoFocus) {
      innerRef.focus();
    }
  }, [isFocused, innerRef, autoFocus])

  useEffect(() => {
    let parsedValue = null;
    if (value !== null && typeof value === 'object' && Object.keys(value).includes('label') && Object.keys(value).includes('value')) {
      parsedValue = value;
    }

    setValueSelected(parsedValue);
  }, [value])

  return (
    <>
      <AsyncSelect
        classNamePrefix={classNamePrefix}
        placeholder={placeholder}
        isClearable={true}
        defaultOptions={defaultOptions}
        blurInputOnSelect={true}
        loadOptions={loadOptions}
        isDisabled={disabled}
        isLoading={isBusy}
        onCreateOption={handleCreateOption}
        onChange={handleChange}
        createOptionPosition={createOptionPosition}
        isValidNewOption={isValidNewOption}
        formatCreateLabel={formatCreateLabel}
        noOptionsMessage={noOptionsMessage}
        onKeyDown={throwKeyHomeEnd}
        value={valueSelected}
        ref={handleRef}
        styles={customStyles}
        {...rest}
      />
      <FormFeedback style={{ display: invalid ? 'block': 'none' }}>{invalidMsg}</FormFeedback>
    </>
  )
}

AutoComplete.defaultProps = {
  placeholder: "Selecione...",
  positionCreate: 'last',
  disabled: false,
  showCreate: false,
  value: null,
  optionLabel: 'name',
  isFocused: false,
  autoFocus: false,
  defaultOptions: false,
  aditionalFilters: {},
  imgLabel: false,
  invalid: false,
  invalidMsg: 'Campo inválido',
  classNamePrefix: null,
  styles: { control: {}, valueContainer: {} }
}

export default AutoComplete;
