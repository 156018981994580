import React from 'react'
import ReactInputMask from 'react-input-mask';
import { Input } from 'reactstrap';

const InputMask = ({ className, mask, id, onChange, placeholder, value, invalid, valid, title, maskChar, key, onBlur, onFocus, onKeyDown, onKeyUp, disabled, readOnly, inputRef, ...rest }) => {
  return <ReactInputMask key={key} className={className} mask={mask} id={id} title={title} onChange={onChange} placeholder={placeholder} value={value ? value : ''} invalid={invalid} valid={valid} maskChar={maskChar} onBlur={onBlur} onFocus={onFocus} onKeyDown={onKeyDown} onKeyUp={onKeyUp} disabled={disabled} readOnly={readOnly} {...rest} >
    {
      props => {
        return <Input {...props} className={className} id={id} title={title} onChange={onChange} placeholder={placeholder} value={value} invalid={invalid} valid={valid} ref={inputRef} onBlur={onBlur} onFocus={onFocus} onKeyDown={onKeyDown} onKeyUp={onKeyUp} disabled={disabled} readOnly={readOnly} />
      }
    }
  </ReactInputMask>
}

InputMask.defaultProps = {
  className: 'form-control',
  onChange: () => {},
  maskChar: '_'
}

export default InputMask;
