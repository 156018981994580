import React, { Suspense, lazy, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";

import AppHeader from "../AppHeader";
import AppSidebar from "../AppSidebar";
import Loader from "react-loaders";

import menuPermissions from "../../services/menuPermissions";
import { ErrorToast } from "./Components/Toast";
import { ListRoutes } from "../AppNav/NavItems";
import { setActiveLinkTo } from "../../reducers/AppConfig";

import '../../assets/layout/css/style.css';
import CriticalEvents from "./Components/CriticalEvents";
import ChatMovidesk from "./Components/ChatMovidesk";

const Dashboard = lazy(() => import("../../Pages/Dashboard"));
const Users =  lazy(() =>  import("../../Pages/User"));
const Profile =  lazy(() =>  import("../../Pages/Profile"));
const Log = lazy(() => import("../../Pages/Log"));
const Customer = lazy(() => import("../../Pages/Customer"));
const Charge = lazy(() => import("../../Pages/Charge"));
const Installment = lazy(() => import("../../Pages/Installment"));
const Subscription = lazy(() => import("../../Pages/Subscription"));
const PaymentLink = lazy(() => import("../../Pages/PaymentLink"));
const Extract = lazy(() => import("../../Pages/Extract"));
const Payment = lazy(() => import("../../Pages/Payment"));
const Transfer = lazy(() => import("../../Pages/Transfer"));
const Company = lazy(() => import("../../Pages/Company"));
const Serasa = lazy(() => import("../../Pages/Serasa"));
const DebtProtest = lazy(() => import("../../Pages/DebtProtest"));

const AppMain = (props) => {
  const accessValidate = (route, user) => {
    let routeAdmin = ['/logs', '/usuarios', '/usuarios/cadastrar']

    if (user.level === 1) {
      return true;
    } else if (routeAdmin.includes(route)) {
      return false;
    } else if (!menuPermissions()[`/#${route}`]) {
      return true;
    } else if (menuPermissions()[`/#${route}`].includes(user.level)) {
      return true;
    }

    return false;
  }

  const verifyActiveLink = () => {
    let listRoutes = ListRoutes();
    if (!!listRoutes[`/#${props.history.location.pathname}`]) {
      setTimeout(() => props.setActiveLinkTo(`/#${props.history.location.pathname}`), 100);
    } else {
      let hasLink = false
      for(let i in listRoutes) {
        if (`/#${props.history.location.pathname}`.indexOf(i) !== -1 && i !== '/#/') {
          hasLink = true
          setTimeout(() => props.setActiveLinkTo(i), 100);
        }
      }

      if (!hasLink) {
        setTimeout(() => props.setActiveLinkTo(`/#${props.history.location.pathname}`), 100);
      }
    }
  }

  useEffect(() => {
    verifyActiveLink();
    props.history.listen((location) => {
      verifyActiveLink();
      if (!accessValidate(location.pathname, props.user)) {
        ErrorToast({ placeholder: 'Você não possui permissão de acesso à essa página! Caso necessite, entre em contato com o administrador do sistema!' })
        props.history.push('/');
      }
    });
  }, []);

  return (
    <>
      <Suspense fallback={
        <div className="loader-container">
          <div className="loader-container-inner">
            <div className="text-center">
              <Loader show={true} type="ball-spin-fade-loader"/>
            </div>
            <h6 className="mt-5">
              Carregando...
            </h6>
          </div>
        </div>
      }>
        {
            (props.user.level === 1 && props.user.company && props.user.company.critical_events.length > 0) &&
            <CriticalEvents />
        }
        <AppHeader {...props} />
        <div className="app-main">
          <AppSidebar />
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Switch>
                  {/** Aqui deve ser definidas as rotas do sistema */}
                  <Route exact path="/" render={({match}) => <Dashboard {...props} match={match} />
                  } />
                  <Route path="/usuarios" render={({match}) => <Users {...props} match={match} />
                  } />
                  <Route path="/perfil" render={({match}) => <Profile {...props} match={match} />
                  } />
                  <Route path="/logs" render={({match}) => <Log {...props} match={match} />
                  } />
                  <Route path="/clientes" render={({match}) => <Customer {...props} match={match} />
                  } />
                  <Route path="/cobrancas" render={({match}) => <Charge {...props} match={match} />
                  } />
                  <Route path="/parcelamentos" render={({match}) => <Installment {...props} match={match} />
                  } />
                  <Route path="/assinaturas" render={({match}) => <Subscription {...props} match={match} />
                  } />
                  <Route path="/links-de-pagamentos" render={({match}) => <PaymentLink {...props} match={match} />
                  } />
                  <Route path="/extrato" render={({match}) => <Extract {...props} match={match} />
                  } />
                  <Route path="/pagamentos" render={({match}) => <Payment {...props} match={match} />
                  } />
                  <Route path="/transferencias" render={({match}) => <Transfer {...props} match={match} />
                  } />
                  <Route path="/configuracoes" render={({match}) => <Company {...props} match={match} />
                  } />
                  <Route path="/serasa" render={({match}) => <Serasa {...props} match={match} />
                  } />
                  <Route path="/negativacao" render={({match}) => <DebtProtest {...props} match={match} />
                  } />
                  <Route render={() => <Redirect to="/" />} />
              </Switch>
              <ChatMovidesk />
            </div>
          </div>
        </div>
      </Suspense>
    </>
  )
};

const mapStateToProps = (state) => ({
  user: state.AppConfig.user
})

const mapDispatchToProps = (dispatch) => ({
  setActiveLinkTo: (link) => dispatch(setActiveLinkTo(link))
})

export default connect(mapStateToProps, mapDispatchToProps)(AppMain);
