import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setLoading } from "../../../reducers/AppConfig";

import { RiEyeCloseLine } from 'react-icons/ri';
import { ImEye } from 'react-icons/im'
import { Col, Row, Button, Form, FormGroup, Label, Input, InputGroupAddon, InputGroup, InputGroupText, CustomInput } from "reactstrap";
import { Link } from "react-router-dom";
import { ErrorToast } from "../../../Layout/AppMain/Components/Toast";

import api from "../../../services/api";
import { formatPassword, dateNow } from 'nfutils';
// Layout

const Login = ({ history, setLoading, isAuthenticated }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        remember_me: false
    });

    const [viewPassword, setViewPassword] = useState(false);

    const toggleViewPassword = () => {
        setViewPassword(!viewPassword);
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
        handleLogin();
        }
    }

    const handleChange = (value, prop) => {
        if (prop === 'password') {
        value = formatPassword(value);
        }

        setFormData({
        ...formData,
        [prop]: value
        })
    }

    const handleValidate = () => {
        if (!formData.email || !formData.password) {
        ErrorToast({ placeholder: "Preencha e-mail e senha para continuar!" });
        return false;
        }

        return true;
    }

    const handleFormData = () => {
        return {
        ...formData,
        }
    }

    const handleLogin = async () => {
        if (!handleValidate()) {
        return;
        }

        setLoading(true);
        api.get('../sanctum/csrf-cookie').then(r => {
        api.post('login', handleFormData()).then(response => {
            localStorage.setItem('@userInfo', JSON.stringify(response.data));
            setLoading(false);
            window.location.assign('/');
        }).catch(e => {
            console.log(e);
            setLoading(false);
            let msg = e.errors ? e.errors.join("<br/>") : "Houve um problema com o login, verifique suas credenciais.";
            ErrorToast({ placeholder: msg });
        });
        }).catch(e => {
        console.log(e);
        setLoading(false);
        let msg = e.errors ? e.errors.join("<br/>") : "Ocorreu um erro interno ao tentar realizar o login, tente novamente mais tarde!";
        ErrorToast({ placeholder: msg });
        })
    }

    useEffect(() => {
      if (isAuthenticated) {
        history.push('/');
      }
    }, [isAuthenticated])

    return (
        <>
          <div className="min-vh-100 bg-plum-plate bg-animation">
            <div className="d-flex h-100 justify-content-center align-items-center">
              <Col md="8" className="mx-auto app-login-box">
                <div className="app-logo-inverse mx-auto mb-3" style={{ height: '8rem' }} />
                <div className="modal-dialog w-100 mx-auto" style={{margin: '1rem auto'}}>
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="h5 modal-title text-center">
                        <h4 className="mt-2">
                          <div>Bem vindo,</div>
                          <span>Preencha os dados abaixo para acessar o sistema!</span>
                        </h4>
                      </div>
                      <Form>
                        <Row form>
                          <Col md={12}>
                              <FormGroup>
                                  <Label>E-mail</Label>
                                  <Input type="email" value={formData.email} onChange={({ target: { value } }) => handleChange(value, 'email')} placeholder="Digite seu e-mail aqui..." autoComplete="username" onKeyUp={handleKeyUp} />
                              </FormGroup>
                          </Col>
                          <Col md={12}>
                              <FormGroup>
                                  <Label>Senha</Label>
                                  <InputGroup>
                                      <Input type={viewPassword ? 'text' : 'password'} value={formData.password} onChange={({ target: { value } }) => handleChange(value, 'password')} placeholder="Digite sua senha aqui..." autoComplete="current-password" onKeyUp={handleKeyUp}/>
                                      <InputGroupAddon addonType="append" onClick={toggleViewPassword}>
                                          <InputGroupText>
                                          <RiEyeCloseLine hidden={viewPassword}/>
                                          <ImEye hidden={!viewPassword} />
                                          </InputGroupText>
                                      </InputGroupAddon>
                                  </InputGroup>
                              </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <CustomInput type="checkbox" id="remember_me" checked={formData.remember_me} onChange={({ target: { checked } }) => handleChange(checked, 'remember_me')} label="Me mantenha logado" />
                        </FormGroup>
                      </Form>
                      <div className="divider" />
                      <h6 className="mt-3">
                        Ainda não possui uma conta?{" "}
                        <Link to="/register" className="text-primary">
                          Crie grátis
                        </Link>
                      </h6>
                    </div>
                    <div className="modal-footer clearfix">
                      <div className="float-left">
                          <Link id="forgot_password" className="btn-lg btn btn-link" to="/forgot-password">
                            Recuperar Senha
                          </Link>
                      </div>
                      <div className="float-right">
                          <Button type="button" color="primary" size="lg" onClick={handleLogin}>
                            Login
                          </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center text-white opacity-8 mt-3">
                  Copyright &copy; WFPay Gestão Financeira {dateNow(null, 'YYYY')}
                </div>
              </Col>
            </div>
          </div>
        </>
      );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.AppConfig.isAuthenticated,
})

const mapDispatchToProps = (dispatch) => ({
  setLoading: (enable, text = null) => dispatch(setLoading(enable, text))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);
