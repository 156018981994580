import { getInfoUser } from "../services/auth";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal);

export const SET_LOADING = 'AppConfig/SET_LOADING';
export const SET_LOAD_ICON = 'AppConfig/SET_LOAD_ICON';
export const SET_BALANCE = 'AppConfig/SET_BALANCE';
export const SET_SHOW_BALANCE = 'AppConfig/SET_SHOW_BALANCE';
export const SET_UPDATE_USER = 'AppConfig/SET_UPDATE_USER';
export const SET_IS_AUTHENTICATED = 'AppConfig/SET_IS_AUTHENTICATED';
export const SET_IS_BLOCKED = 'AppConfig/SET_IS_BLOCKED';
export const SET_ACTIVE_LINK_TO = 'AppConfig/SET_ACTIVE_LINK_TO';
export const SET_USER = 'AppConfig/SET_USER'

export const setLoading = (loading, loadingText = null) => ({
  type: SET_LOADING,
  loading,
  loadingText
})

export const setLoadIcon = loadIcon => ({
  type: SET_LOAD_ICON,
  loadIcon
})

export const setBalance = balance => ({
  type: SET_BALANCE,
  balance
})

export const setShowBalance = showBalance => ({
  type: SET_SHOW_BALANCE,
  showBalance
})

export const setUpdateUser = updateUser => ({
  type: SET_UPDATE_USER,
  updateUser
})

export const setIsAuthenticated = isAuthenticated => ({
  type: SET_IS_AUTHENTICATED,
  isAuthenticated
})

export const setIsBlocked = isBlocked => ({
  type: SET_IS_BLOCKED,
  isBlocked
})

export const setActiveLinkTo = activeLinkTo => ({
  type: SET_ACTIVE_LINK_TO,
  activeLinkTo
})

export const setUser = user => ({
  type: SET_USER,
  user
})

export default function reducer(state = {
  loading: false,
  loadingText: null,
  loadIcon: 'ball-clip-rotate-multiple',
  balance: 0.00,
  showBalance: true,
  updateUser: false,
  isAuthenticated: null,
  isBlocked: false,
  env: process.env.REACT_APP_ENV || 'production',
  activeLinkTo: '',
  user: getInfoUser() || {},
  sweetAlert: MySwal
}, action) {
  switch(action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
        loadingText: action.loadingText
      }
    case SET_LOAD_ICON:
      return {
        ...state,
        loadIcon: action.loadIcon
      }
    case SET_BALANCE:
      return {
        ...state,
        balance: action.balance
      }
    case SET_SHOW_BALANCE:
      return {
        ...state,
        showBalance: action.showBalance
      }
    case SET_UPDATE_USER:
      return {
        ...state,
        updateUser: action.updateUser
      }
    case SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated
      }
    case SET_IS_BLOCKED:
      return {
        ...state,
        isBlocked: action.isBlocked
      }
    case SET_ACTIVE_LINK_TO:
      return {
        ...state,
        activeLinkTo: action.activeLinkTo
      }
    case SET_USER:
      return {
        ...state,
        user: action.user
      }
    default:
  }

  return state
}
