import React from 'react'
import { FiRefreshCw } from 'react-icons/fi'
import CustomTooltip from '../CustomTooltip'
import './style.css'

export const ModalLoading = () => (
    <div className="modal-content isLoading">
        <span className='text-center'>
            <LoadingIcon size={30} color="var(--dark)" title="Carregando..." isLoading={true} />
            <br />
            <b>Carregando...</b>
        </span>
    </div>
)

const LoadingIcon = ({ size, style, color, isLoading, title, onClick }) => <CustomTooltip input={<FiRefreshCw id="custom_loading_icon" className={isLoading ? 'loading' : ''} size={size} style={style} color={color} onClick={onClick} />} target="custom_loading_icon" textAlign="center" tooltipText={title} />

LoadingIcon.defaultProps = {
  style: {},
  color: 'var(--primary)',
  isLoading: false,
  title: 'Atualizar informações',
  size: 15,
  onClick: () => {}
}

export default LoadingIcon
