export function cardsMasks(cardNumber){
  // Amex
  if(/^3[47]/.test(cardNumber)){
    return '9999 999999 99999';
  }
  // Diners
  if(/^3(?:0[0-5]|[68])/.test(cardNumber)){
    return '9999 999999 9999';
  }

  return '9999 9999 9999 9999';
}
