import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { setLoading, setUpdateUser } from "../../../reducers/AppConfig";

import { FaRegBell } from "react-icons/fa";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  Button,
  NavItem,
  Row,
  Col,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import api from "../../../services/api";
import { ErrorToast } from "../../AppMain/Components/Toast";

const HeaderDots = ({ location, notifications, updateUser, setUpdateUser }) => {
  const [ limitNotifications, setLimitNotifications ] = useState(15)
  const [ newNotifications, setNewNotifications ] = useState({
    in_view: null,
    total: null
  })
  const [ allNotifications, setAllNotifications ] = useState([])
  const [ disabledButton, setDisabledButton ] = useState(false)

  const handleClickNotification = (notification) => {
    if (notification.url) {
      window.open(notification.url)
    }

    if (!notification.date_viewed) {
      api.post(`notifications/${notification.id}/viewed`)
      .then(response => {
        setAllNotifications(allNotifications.map(not => {
          if (not.id === notification.id) {
            return {
              ...response.data
            }
          }

          return not
        }))
      })
      .catch(e => {
        console.log(e)
      })
    }
  }

  const handleViewedAllNotifications = () => {
    setDisabledButton(true)
    api.post('notifications/viewed/all')
    .then(_ => {
      setUpdateUser(!updateUser)
      setDisabledButton(false)
    })
    .catch(e => {
      console.log(e)
      setDisabledButton(false)
      ErrorToast({ placeholder: 'Falha ao tentar marcar notificações como lidas, tente novamente mais tarde' })
    })
  }

  useEffect(() => {
    setAllNotifications([...notifications])
  }, [notifications])

  useEffect(() => {
    let total = allNotifications.filter((notification, i) => (!notification.date_viewed))
    let in_view = total.filter((_, i) => (i < limitNotifications)).length
    if (in_view === 0) {
      in_view = null
    }
    total = total.length

    setNewNotifications({ in_view, total })
  }, [allNotifications, limitNotifications])
  return (
    location.pathname !== "/bloqueado" && <Fragment>
      <div className="header-dots">
        <UncontrolledDropdown>
          <DropdownToggle className="p-0 mr-2" color="link">
            <div className="icon-wrapper icon-wrapper-alt rounded-circle">
              <div className="icon-wrapper-bg bg-danger" />
              <FaRegBell color="#d92550" fontSize="20px" />
              <div className="badge badge-dot badge-dot-sm badge-danger">
                {newNotifications.in_view}
              </div>
            </div>
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-xl rm-pointers">
            <div className="dropdown-menu-header mb-0">
              <div className="dropdown-menu-header-inner bg-deep-blue">
                <div className="menu-header-content text-dark">
                  <h5 className="menu-header-title">Notificações</h5>
                  <h6 className="menu-header-subtitle" hidden={!newNotifications.in_view}>
                    Você tem <b>{newNotifications.total}</b> novas notificações
                  </h6>
                </div>
              </div>
            </div>
            <PerfectScrollbar style={{ maxHeight: 350, overflowY: 'auto' }} >
              {
                allNotifications.filter((_, i) => (i < limitNotifications)).map((notification, i) => {
                  let type = 'danger'
                  if (notification.type === 1) {
                    type = 'info'
                  } else if (notification.type === 2) {
                    type = 'warning'
                  } else if (notification.type === 3) {
                    type = 'success'
                  }
                  return <Row key={i} className="pl-3 pr-3 pt-3" style={{ borderBottom: '1px solid #cecece', cursor: notification.url ? 'pointer' : 'text' }} onClick={() => handleClickNotification(notification)}>
                    <Col md={12}>
                      <Row>
                        <Col md={10} sm={10} xs={10}>
                          <p><b>{notification.title}</b></p>
                        </Col>
                        <Col>
                          <div className={`badge badge-dot badge-dot-sm badge-${type}`}>
                            {notification.date_viewed ? false : 1}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12}>
                      <p>{notification.message}</p>
                    </Col>
                  </Row>
                })
              }
              <Row className="p-3" hidden={limitNotifications >= allNotifications.length}>
                <Col md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button size="sm" className="btn-shadow btn-wide btn-pill" color="primary" onClick={() => setLimitNotifications((limitNotifications + 15))}>
                    Ver mais...
                  </Button>
                </Col>
              </Row>
            </PerfectScrollbar>
            <Nav vertical>
              <NavItem className="nav-item-divider" />
              <NavItem className="nav-item-btn text-center">
                <Button disabled={disabledButton} size="sm" className="btn-shadow btn-wide btn-pill" color="dark" onClick={handleViewedAllNotifications}>
                  Marcar como lidas
                </Button>
              </NavItem>
            </Nav>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </Fragment>
  )
}

HeaderDots.defaultProps = {
  notifications: []
}

const mapStateToProps = (state) => ({
  updateUser: state.AppConfig.updateUser
})

const mapDispatchToProps = (dispatch) => ({
  setUpdateUser: (enable) => dispatch(setUpdateUser(enable))
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDots)
