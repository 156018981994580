import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { setLoading } from '../../reducers/AppConfig';
import { Link } from 'react-router-dom';

import DataBanner from '../Company/components/DataBanner';
import ReactSelect from '../../Layout/AppMain/Components/ReactSelect';
import InputCurrency from '../../Layout/AppMain/Components/InputCurrency';
import InputMask from '../../Layout/AppMain/Components/InputMask';
import AutoComplete from '../../Layout/AppMain/Components/AutoComplete';
import ModalDialog from '../../Layout/AppMain/Components/ModalDialog';
import { Button, Col, CustomInput, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ErrorToast, SuccessToast } from '../../Layout/AppMain/Components/Toast';
import { FaBarcode, FaRegCreditCard } from 'react-icons/fa';
import { FaPix } from 'react-icons/fa6';
import { ModalLoading } from '../../Layout/AppMain/Components/LoadingIcon';
import { optionsTypePayment } from '../../services/defaultOptions';

import api from '../../services/api';
import validator from 'validator'
import buscaCep from '../../services/apiCep';
import { dateNow, formatCpfCnpj, formatFloat, formatValue, isCpfCnpj, isMobile, onlyNumber } from 'nfutils';
import CardInputToken from './components/CardInputToken';
import { cardsMasks } from '../../services/cardsMasks';
import { CardIcons } from '../../Layout/AppMain/Components/CardIcons/Index';

const PaymentLinkPub = ({ match, setLoading, history }) => {
  const { id } = match.params
  const [showInfoCustomer, setShowInfoCustomer] = useState(false);
  const [showInfoCard, setShowInfoCard] = useState(false);
  const [validation, setValidation] = useState({});
  const [updateAddress, setUpdateAddress] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentImg, setCurrentImg] = useState(0);
  const [charge, setCharge] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [ state, setState ] = useState({
    name: '',
    description: '',
    fixed_value: false,
    value: '',
    payment_by: 1,
    max_quantity_installments: 1,
    payment_by_invoice: true,
    payment_by_card: true,
    payment_by_pix: true,
    type_payment: 1,
    cycle: null,
    quantity_installments: { value: 1, label: 'À vista (R$ 0,00)' },
    payment_link_images: [],
    company: {},
    customer: {
      email: '',
      name: '',
      cpfcnpj: '',
      phone: '',
      cellphone: '',
      cep: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: null,
      state: null
    },
    card: {
      cpfcnpj: '',
      name: '',
      cvv: '',
      month: '',
      year: ''
    },
  });
  const [optionsInstallments, setOptionsInstallments] = useState({
    1: { value: 1, label: 'À vista (R$ 0,00)' }
  });
  const optionsCycle = {
    1: { value: 1, label: 'Semanal' },
    2: { value: 2, label: 'Quinzenal' },
    3: { value: 3, label: 'Mensal' },
    4: { value: 4, label: 'Bimestral' },
    5: { value: 5, label: 'Trimestral' },
    6: { value: 6, label: 'Semestral' },
    7: { value: 7, label: 'Anual' }
  }

  const handleChange = (value, prop) => {
    let newState = {...state}
    newState[prop] = value

    setState(newState)
  }

  const handleChangeCard = (value, prop) => {
    let card = {...state.card}
    let customer = {...state.customer}
    card[prop] = value

    if (['name', 'cpfcnpj'].includes(prop)) {
      customer[prop] = value
    }

    setState({ ...state, card, customer })
  }

  const handleChangeCustomer = (value, prop) => {
    let customer = {...state.customer}
    customer[prop] = value

    if (prop === 'cep' && onlyNumber(value).length === 8) {
      setUpdateAddress(!updateAddress)
    }
    if (prop === 'state') {
      customer.city = null
    }
    if (prop === 'city' && value) {
      customer.state = {
        value: value.data.state.id,
        label: value.data.state.sigla,
        data: value.data.state
      }
    }

    setState({ ...state, customer })
  }

  const handleNext = () => {
    if (!showInfoCard && !showInfoCustomer) {
      if (!handleValidate(1)) {
        return
      }

      if (state.payment_by === 1 || state.payment_by === 4) {
        setShowInfoCustomer(true)
      } else {
        setShowInfoCard(true)
      }
    } else if (showInfoCard && !showInfoCustomer) {
      if (!handleValidate(2)) {
        return
      }

      setShowInfoCard(false)
      setShowInfoCustomer(true)
    }
  }

  const handlePrevious = () => {
    if (showInfoCustomer) {
      setShowInfoCustomer(false)
      if (state.payment_by === 2) {
        setShowInfoCard(true)
      }
    } else if (showInfoCard) {
      setShowInfoCard(false)
    }
  }

  const handleBack = () => {
    history.push('/');
  }

  /**
   * Função responsável por válidar os campos do formulário
   *
   * @param level Número inteiro indicando o tipo de validação
   *              - 1 = Valida os campos da tela inicial do formulário
   *              - 2 = Valida os campos da tela de cartão
   *              - 3 = Valida os campos da tela de informações do comprador
   *              - 4 = Valida um campo específico
   *              - 5 = Valida todos os campos do formulário
   * @param input Indica o campo que deve ser válidado (Quando level = 4)
   *
   * @return boolean
   */
  const handleValidate = (level, input) => {
    let invalid = {}
    if (!!input) {
      invalid = { ...validation }
      invalid[input] = false
    }

    //Valida o valor digitado
    if ([1, 4, 5].includes(level)) {
      if (!state.fixed_value && (!state.value || (state.value < 15 && state.payment_by === 1) || (state.value < 5 && state.payment_by === 2)) && (!input || input === 'value')) {
        invalid.value = true;
      }
    }

    //Valida os campos do cartão de crédito
    if ([2, 4, 5].includes(level) && state.payment_by === 2) {
      if (!isCpfCnpj(state.card.cpfcnpj) && (input === 'card_cpfcnpj' || !input)) {
        invalid.card_cpfcnpj = true
      }
      if ((!state.card.name || !state.card.name.trim() || state.card.name.length < 3 || (state.card.name.split(' ')).length < 2) && (input === 'card_name' || !input)) {
        invalid.card_name = true
      }
      if ((!state.card.month || !state.card.month.trim() || state.card.month.length !== 2 || state.card.month < 1 || state.card.month > 12) && (input === 'card_month' || !input)) {
        invalid.card_month = true
      }
      if ((!state.card.year || !state.card.year.trim() || onlyNumber(state.card.year).length !== 4 || state.card.year < dateNow(null, 'YYYY')) && (input === 'card_year' || !input)) {
        invalid.card_year = true
      }
      if ((!state.card.cvv || !state.card.cvv.trim() || state.card.cvv.length < 3 || state.card.cvv.length > 4) && (input === 'card_cvv' || !input)) {
        invalid.card_cvv = true
      }
    }

    //Valida os campos do comprador
    if ([3, 4, 5].includes(level)) {
      if ((!state.customer.name || !state.customer.name.trim() || state.customer.name.length < 3) && (input === 'customer_name' || !input)) {
        invalid.customer_name = true
      }
      if (!validator.isEmail(state.customer.email) && (input === 'customer_email' || !input)) {
        invalid.customer_email = true
      }
      if (!isCpfCnpj(state.customer.cpfcnpj) && (input === 'customer_cpfcnpj' || !input)) {
        invalid.customer_cpfcnpj = true
      }
      if ((!state.customer.cellphone || !state.customer.cellphone.trim() || onlyNumber(state.customer.cellphone).length !== 11) && (input === 'customer_cellphone' || !input)) {
        invalid.customer_cellphone = true
      }
      if ((!state.customer.cep || !state.customer.cep.trim() || onlyNumber(state.customer.cep).length !== 8) && (input === 'customer_cep' || !input)) {
        invalid.customer_cep = true
      }
      if ((!state.customer.address || !state.customer.address.trim() || state.customer.address.length < 10 || state.customer.address.length > 200) && (input === 'customer_address' || !input)) {
        invalid.customer_address = true
      }
      if ((!state.customer.number || !state.customer.number.trim() || state.customer.number.length > 10) && (input === 'customer_number' || !input)) {
        invalid.customer_number = true
      }
      if ((!state.customer.neighborhood || !state.customer.neighborhood.trim() || state.customer.neighborhood.length < 5 || state.customer.neighborhood.length > 60) && (input === 'customer_neighborhood' || !input)) {
        invalid.customer_neighborhood = true
      }
      if (state.customer.complement.length > 100 && (input === 'customer_complement' || !input)) {
        invalid.customer_complement = true
      }
      if (!state.customer.state && (input === 'customer_state' || !input)) {
        invalid.customer_state = true
      }
      if (!state.customer.city && (input === 'customer_city' || !input)) {
        invalid.customer_city = true
      }
    }

    setValidation(invalid)

    if (Object.keys(invalid).length > 0) {
      return false
    }

    return true
  }

  const handleFormData = () => {
    return {
      ...state,
      quantity_installments: state.type_payment === 1 ? state.quantity_installments.value : null,
      card: {
        token: state.card.token,
        brand: state.card.bandeira,
        name: state.card.name,
        month: onlyNumber(state.card.month),
        year: onlyNumber(state.card.year),
        cvv: onlyNumber(state.card.cvv),
        cpfcnpj: onlyNumber(state.card.cpfcnpj)
      },
      customer: {
        ...state.customer,
        cpfcnpj: onlyNumber(state.customer.cpfcnpj),
        cep: onlyNumber(state.customer.cep),
        phone: onlyNumber(state.customer.phone),
        cellphone: onlyNumber(state.customer.cellphone),
        state_id: state.customer.state.value,
        city_id: state.customer.city.value,
      }
    }
  }

  const handleSubmit = async () => {
    if (!handleValidate(5)) {
      ErrorToast({ placeholder: 'Preencha todos os campos obrigatórios antes de continuar' })
      return
    }

    try {
      setLoading(true)
      let response = await api.post(`c/${state.id}/charge`, handleFormData())
      setCharge({...response.data})
    } catch (e) {
      console.log(e)
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar gerar a cobrança, tente novamente mais tarde!'
      ErrorToast({ placeholder: msg })
    } finally {
      setLoading(false)
    }
  }

  const handleViewPdf = () => {
    if (!charge) {
      return
    }

    if (!charge.charges || state.type_payment !== 2) {
      window.open(charge.pdf_url);
    } else if (state.type_payment === 2) {
      window.open(charge.charges[0].pdf_url);
    }
  }

  const getData = async () => {
    try {
      setIsLoading(true)
      let response = await api.get(`c/${id}`)

      setState({
        ...state,
        ...response.data,
        max_quantity_installments: response.data.quantity_installments,
        quantity_installments: optionsInstallments[1],
        payment_by: response.data.payment_by_invoice === 1 ? 1 :
                    response.data.payment_by_card === 2 ? 2 : 4,
      })
    } catch (e) {
      console.log(e)
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao buscar as informações do link de pagamento, tente novamente mais tarde'
      ErrorToast({ placeholder: msg })
      handleBack()
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (typeof id !== 'undefined') {
      getData()
    }
  }, [id])

  useEffect(() => {
    if (state.type_payment === 2) {
      return
    }

    let installments = parseInt((state.value / 15))
    let newOptions = {};
    if (installments > 1 && state.max_quantity_installments > 1) {
      installments = installments > state.max_quantity_installments ? state.max_quantity_installments : installments
      for (let i = 1; i <= installments; i++) {
        let value = (state.value / i).toFixed(2)
        value = value - (value * i - state.value).toFixed(2)
        let option = {
          value: i,
          label: i === 1 ? `À vista (${formatValue({ value, currency: 'BRL' })})` : `${i} parcelas de ${formatValue({ value, currency: 'BRL' })}`
        }
        newOptions[i] = option
      }
    } else {
      newOptions[1] = {
        value: 1,
        label: `À vista (${formatValue({ value: state.value, currency: 'BRL' })})`
      }
    }

    let quantity_installments = Object.values(newOptions).pop()

    setOptionsInstallments(newOptions)
    setState({...state, quantity_installments});
  }, [state.value])

  useEffect(() => {
    if (state.customer && onlyNumber(state.customer.cep).length === 8) {
      setLoading(true)
      buscaCep(state.customer.cep)
      .then(response => {
        setState({ ...state, customer: {
          ...state.customer,
          ...response,
          number: '',
          complement: '',
          city: {
            value: response.city_id,
            label: response.city
          },
          state: {
            value: response.state_id,
            label: response.uf
          },
        } })
      })
      .catch(e => {
        console.log(e)
      })
      .finally(_ => {
        setLoading(false)
      })
    }
  }, [updateAddress])

  return (
    <>
      <Row className="payment-link-grid" style={{ margin: isMobile() ? '0 -15px' : '32px 64px' }}>
        {
          !charge &&
          <>
            <Col md={8} sm={12} xs={12} className="mb-2">
              {
                isLoading
                ?
                <ModalLoading />
                :
                <div className="modal-content">
                    <DataBanner state={state.company || {}} />
                    <div className="modal-body">
                    {
                        (!showInfoCard && !showInfoCustomer) &&
                        <>
                        <h4 className="text-focus">{state.name}</h4>
                        {
                            state.payment_link_images.length > 0 &&
                            <div className="image-grid">
                                {
                                    state.payment_link_images.map((img, key) => {
                                        return <div key={key} className={`payment-link-img ${img.main ? 'main' : 'secondary'}-image`} data-img-index={key} style={{ cursor: 'pointer' }} onClick={() => {
                                            setCurrentImg(key);
                                            setOpenDialog(true);
                                        }}>
                                            <div style={{ backgroundImage: `url(${img.url})` }} className="payment-link-img-cover" />
                                        </div>
                                    })
                                }
                            </div>
                        }
                        <Row>
                            {
                            state.description &&
                            <Col md={12} sm={12} xs={12}>
                                <FormGroup>
                                <Label className="text-secondary">Descrição</Label>
                                <br />
                                <span>{state.description}</span>
                                </FormGroup>
                            </Col>
                            }
                            <Col md={6} xs={12} sm={12}>
                            <FormGroup>
                                <Label className="text-secondary" for="value">Valor {(!state.fixed_value && state.type_payment === 2) && `(${optionsCycle[state.cycle].label.toLowerCase()})`}</Label>
                                {
                                state.fixed_value &&
                                <>
                                    <br />
                                    <span className="d-flex"><h5 className="mr-1">{formatValue({ value: state.value, currency: 'BRL' })}</h5> {state.type_payment === 2 && <span className="text-secondary">({optionsCycle[state.cycle].label.toLowerCase()})</span>}</span>
                                </>
                                }
                                {
                                !state.fixed_value &&
                                <>
                                    <InputCurrency invalid={validation.value} invalidMsg={`O valor mínimo para pagamento no ${state.payment_by === 1 ? 'boleto bancário' : 'cartão de crédito'} é de R$ ${state.payment_by === 1 ? '15' : '5'},00`} placeholder='R$ 0,00' allowNegativeValue={false} prefix="R$ " precision={12} value={state.value} onChange={value => handleChange(formatFloat({ value }), 'value')} onBlur={() => handleValidate(4, 'value')} />
                                </>
                                }
                            </FormGroup>
                            </Col>
                        </Row>
                        {
                            (state.payment_by_card && state.payment_by_invoice || state.payment_by_pix) &&
                            <>
                            <h4>Formas de pagamento</h4>
                            <Row>
                                <Col md={12} sm={12} xs={12}>
                                <FormGroup>
                                    {
                                        state.payment_by_pix &&
                                        <CustomInput id="pix_option" className="mt-2" type='radio' checked={state.payment_by === 4} label={<><FaPix size={15} /> <span>Pix</span></>} onChange={() => handleChange(4, 'payment_by')} />
                                    }
                                    {
                                        state.payment_by_invoice &&
                                        <CustomInput id="boleto_option" className="mt-2" type='radio' checked={state.payment_by === 1} label={<><FaBarcode size={15} /> <span>Boleto Bancário</span></>} onChange={() => handleChange(1, 'payment_by')} />
                                    }
                                    {
                                        state.payment_by_card &&
                                        <CustomInput id="credit_card_option" className="mt-2" type='radio' checked={state.payment_by === 2} label={<><FaRegCreditCard size={15} /> <span>Cartão de Crédito</span></>} onChange={() => handleChange(2, 'payment_by')} />
                                    }
                                </FormGroup>
                                </Col>
                            </Row>
                            </>
                        }
                        {
                            (!state.payment_by_card || !state.payment_by_invoice || !state.payment_by_pix) &&
                            <Row>
                            <Col md={12} sm={12} xs={12}>
                                <FormGroup>
                                <Label className="text-secondary">Forma de Pagamento</Label>
                                <br />
                                <span>{state.payment_by === 1 ? 'Boleto Bancário' : state.payment_by === 2 ? 'Cartão de Crédito' : 'Pix'}</span>
                                </FormGroup>
                            </Col>
                            </Row>
                        }
                        <Row>
                            <Col md={6} sm={12} xs={12}>
                            {
                                state.max_quantity_installments > 1 &&
                                <FormGroup>
                                <Label className="text-secondary">Parcelas</Label>
                                <ReactSelect placeholder="Selecione a parcela..." value={state.quantity_installments} onChange={value => handleChange(value, 'quantity_installments')} options={Object.values(optionsInstallments)} />
                                </FormGroup>
                            }
                            </Col>
                        </Row>
                        <Button color="focus" onClick={handleNext}>{state.fixed_value ? 'Comprar' : 'Contribuir'}</Button>
                        </>
                    }
                    {
                        showInfoCard &&
                        <>
                        <h4>Pagamento com cartão de crédito</h4>
                        <span>Bandeiras aceitas: </span>
                        <br />
                        {
                            CardIcons.map((icon, key) => {
                            return <img key={key} className='px-2' src={icon.src} alt={`card-icon-${key}`} style={{ width: 70, height: 70 }} />
                            })
                        }
                        <Row className="mt-4">
                            <Col md={6} sm={12} xs={12}>
                            <Row>
                                <Col md={12}>
                                <FormGroup>
                                    <Input type="text" placeholder="CPF ou CNPJ do titular do cartão" value={formatCpfCnpj({ value: state.card.cpfcnpj })} onChange={({ target: { value } }) => handleChangeCard(value, 'cpfcnpj')} invalid={validation.card_cpfcnpj} onBlur={() => handleValidate(4, 'card_cpfcnpj')} />
                                    <FormFeedback>Informe um CPF ou CNPJ válido</FormFeedback>
                                </FormGroup>
                                </Col>
                                <Col md={12}>
                                <FormGroup>
                                    <CardInputToken
                                        invalid={validation.card_number}
                                        mask={cardsMasks(state.card.number)}
                                        credencial={state.company?.pjbank_credit_card_credential?.credencial}
                                        sandbox={window.location.href.includes('dev') || window.location.href.includes('sandbox') ? true : false}
                                        maskChar={null}
                                        placeholder="Número do cartão"
                                        value={state.card.number}
                                        onChange={({ target: { value } }) => handleChangeCard(value, 'number')}
                                        onBlur={() => handleValidate(3, 'card_number')}
                                        dataCallback={({ token, bandeira }) => setState({ ...state, card: { ...state.card, token, bandeira } })}
                                    />
                                    <FormFeedback>Informe um número de cartão válido</FormFeedback>
                                </FormGroup>
                                </Col>
                                <Col md={12}>
                                <FormGroup>
                                    <Input invalid={validation.card_name} type="text" className="form-control" placeholder="Nome do titular do cartão" value={state.card.name} onChange={({ target: { value } }) => handleChangeCard(value, 'name')} onBlur={() => handleValidate(4, 'card_name')} />
                                    <FormFeedback>Informe um nome válido</FormFeedback>
                                </FormGroup>
                                </Col>
                                <Col md={12}>
                                <FormGroup>
                                    <InputMask invalid={validation.card_cvv} mask="9999" maskChar={null} placeholder="Código de segurança" value={state.card.cvv} onChange={({ target: { value } }) => handleChangeCard(value, 'cvv')} onBlur={() => handleValidate(4, 'card_cvv')} />
                                    <FormFeedback>Informe um código válido</FormFeedback>
                                </FormGroup>
                                </Col>
                                <Col md={12}>
                                <p>Válido até:</p>
                                </Col>
                                <Col md={6} sm={6} xs={12}>
                                <FormGroup>
                                    <InputMask invalid={validation.card_month} mask="99" maskChar={null} placeholder="Mês" value={state.card.month} onChange={({ target: { value } }) => handleChangeCard(value, 'month')} onBlur={() => handleValidate(4, 'card_month')} />
                                    <FormFeedback>Informe um mês válido</FormFeedback>
                                </FormGroup>
                                </Col>
                                <Col md={6} sm={6} xs={12}>
                                <FormGroup>
                                    <InputMask invalid={validation.card_year} mask="9999" maskChar={null} placeholder="Ano" value={state.card.year} onChange={({ target: { value } }) => handleChangeCard(value, 'year')} onBlur={() => handleValidate(4, 'card_year')} />
                                    <FormFeedback>Informe um ano válido</FormFeedback>
                                </FormGroup>
                                </Col>
                            </Row>
                            </Col>
                            <Col md={6} sm={12} xs={12}></Col>
                        </Row>
                        <Button className="mr-2" color="light" onClick={handlePrevious}>Voltar</Button>
                        <Button className="mr-2" color="focus" onClick={handleNext}>Avançar</Button>
                        </>
                    }
                    {
                        showInfoCustomer &&
                        <>
                        <h4>Informe seus dados</h4>
                        <h6 className="mt-4 mb-3"><b>Dados do comprador</b></h6>
                        <Row>
                            <Col md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Input type="text" placeholder="Nome completo" value={state.customer.name} onChange={({ target: { value } }) => handleChangeCustomer(value, 'name')} invalid={validation.customer_name} onBlur={() => handleValidate(4, 'customer_name')} />
                                <FormFeedback>Informe um nome válido</FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Input type="text" placeholder="CPF ou CNPJ" value={formatCpfCnpj({ value: state.customer.cpfcnpj })} onChange={({ target: { value } }) => handleChangeCustomer(value, 'cpfcnpj')} invalid={validation.customer_cpfcnpj} onBlur={() => handleValidate(4, 'customer_cpfcnpj')} />
                                <FormFeedback>Informe um CPF ou CNPJ válido</FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col md={6} sm={12} xs={12}>
                            <FormGroup>
                                <InputMask invalid={validation.customer_cellphone} mask="(99) 99999-9999" placeholder="Celular" maskChar={null} value={state.customer.cellphone} onChange={({ target: { value } }) => handleChangeCustomer(value, 'cellphone')} onBlur={() => handleValidate(4, 'customer_cellphone')} />
                                <FormFeedback>Informe um celular válido</FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col md={6} sm={12} xs={12}>
                            <FormGroup>
                                <InputMask mask="(99) 9999-9999" placeholder="Telefone (opcional)" maskChar={null} value={state.customer.phone} onChange={({ target: { value } }) => handleChangeCustomer(value, 'phone')} />
                            </FormGroup>
                            </Col>
                            <Col md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Input invalid={validation.customer_email} type="text" placeholder="E-mail" value={state.customer.email} onChange={({ target: { value } }) => handleChangeCustomer(value, 'email')} onBlur={() => handleValidate(4, 'customer_email')} />
                                <FormFeedback>Informe um e-mail válido</FormFeedback>
                            </FormGroup>
                            </Col>
                        </Row>
                        <h6 className="mt-4 mb-3"><b>Endereço de cobrança</b></h6>
                        <Row>
                            <Col md={6} sm={12} xs={12}>
                            <FormGroup>
                                <InputMask invalid={validation.customer_cep} mask="99999-999" placeholder="CEP" maskChar={null} value={state.customer.cep} onChange={({ target: { value } }) => handleChangeCustomer(value, 'cep')} onBlur={() => handleValidate(4, 'customer_cep')} />
                                <FormFeedback>Informe um CEP válido</FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Input invalid={validation.customer_address} type="text" placeholder="Logradouro" value={state.customer.address} onChange={({ target: { value } }) => handleChangeCustomer(value, 'address')} onBlur={() => handleValidate(4, 'customer_address')} />
                                <FormFeedback>Informe um logradouro válido</FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Input invalid={validation.customer_number} type="text" placeholder="Número" maxLength={10} value={state.customer.number} onChange={({ target: { value } }) => handleChangeCustomer(value, 'number')} onBlur={() => handleValidate(4, 'customer_number')} />
                                <FormFeedback>Informe um número válido</FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Input invalid={validation.customer_complement} type="text" placeholder="Complemento" value={state.customer.complement} onChange={({ target: { value } }) => handleChangeCustomer(value, 'complement')} onBlur={() => handleValidate(4, 'customer_complement')} />
                                <FormFeedback>O complemento pode ter no máximo 100 caracteres</FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col md={6} sm={12} xs={12}>
                            <FormGroup>
                                <Input invalid={validation.customer_neighborhood} type="text" placeholder="Bairro" value={state.customer.neighborhood} onChange={({ target: { value } }) => handleChangeCustomer(value, 'neighborhood')} onBlur={() => handleValidate(4, 'customer_neighborhood')} />
                                <FormFeedback>Informe um bairro válido</FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col md={3} sm={12} xs={12}>
                            <FormGroup>
                                <AutoComplete invalid={validation.customer_state} invalidMsg="Selecione um estado válido" urlFind="states" optionLabel="sigla" placeholder="UF" defaultOptions={true} value={state.customer.state} onChange={value => handleChangeCustomer(value, 'state')} onBlur={() => handleValidate(4, 'customer_state')} />
                                <FormFeedback>Selecione um estado válido</FormFeedback>
                            </FormGroup>
                            </Col>
                            <Col md={3} sm={12} xs={12}>
                            <FormGroup>
                                <AutoComplete invalid={validation.customer_city} invalidMsg="Selecione uma cidade válida" urlFind="cities" placeholder="Cidade" defaultOptions={true} value={state.customer.city} onChange={value => handleChangeCustomer(value, 'city')} onBlur={() => handleValidate(4, 'customer_city')} aditionalFilters={{
                                state_id: state.customer.state ? state.customer.state.value : ''
                                }} />
                                <FormFeedback>Selecione uma cidade válida</FormFeedback>
                            </FormGroup>
                            </Col>
                        </Row>
                        <Button className="mr-2" color="light" onClick={handlePrevious}>Voltar</Button>
                        <Button className="mr-2" color="focus" onClick={handleSubmit}>{state.payment_by === 1 ? 'Gerar Boleto' : 'Finalizar Pagamento'}</Button>
                        </>
                    }
                    </div>
                </div>
              }
            </Col>
            <Col md={4} sm={12} xs={12} className="mb-2">
              <div className="container-resume">
                <div className="modal-content">
                  <div className="modal-body">
                    <h4>Resumo</h4>
                    <Row>
                      <Col className="mt-2" md={6}>Nome</Col>
                      <Col className="mt-2" md={6} style={{ textAlign: 'end' }}><b>Link de Teste</b></Col>
                      {
                        state.type_payment === 2 &&
                        <>
                          <Col className="mt-2" md={6}>Frequência de assinatura</Col>
                          <Col className="mt-2" md={6} style={{ textAlign: 'end' }}><b>{optionsCycle[state.cycle].label}</b></Col>
                        </>
                      }
                      <Col className="mt-2" md={6}>Valor</Col>
                      <Col className="mt-2" md={6} style={{ textAlign: 'end' }}><b>{formatValue({ value: state.value, currency: 'BRL' })}</b></Col>
                      <Col className="mt-2" md={6}>Forma de pagamento</Col>
                      <Col className="mt-2" md={6} style={{ textAlign: 'end' }}><b>{optionsTypePayment[state.payment_by].label}</b></Col>
                      {
                        state.quantity_installments.value > 1 &&
                        <>
                          <Col className="mt-2" md={6}>Parcelamento</Col>
                          <Col className="mt-2" md={6} style={{ textAlign: 'end' }}><b>{`${state.quantity_installments.value} x de ${state.quantity_installments.label.replace(`${state.quantity_installments.value} parcelas de `, '')}`}</b></Col>
                        </>
                      }
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </>
        }
        {
          charge &&
          <>
            <Col className="mb-2">
              <div className="modal-content">
                <div className="d-flex justify-content-center align-items-center card float-barcode">
                  <FaBarcode size={20} color="var(--success)" />
                </div>
                <div className="modal-body bg-success text-white" style={{ height: 16, padding: 0 }} />
                <div className="modal-body" style={{ padding: '24px 74px' }}>
                  {
                    charge && state.quantity_installments === 1 && state.payment_by === 2 ?
                        <h3><b>Tudo certo! Recebemos o seu pagamento de {formatValue({ value: state.value, currency: 'BRL' })}</b></h3>
                    :
                        <h3><b>Tudo certo! Agora é só realizar o pagamento {!charge.charges ? `no valor de ${formatValue({ value: charge.value, currency: 'BRL' })}` : `da 1ª parcela no valor de ${formatValue({ value: charge.charges[0].value, currency: 'BRL' })}`}</b></h3>
                  }
                  {
                    (charge.charges && state.type_payment === 1) &&
                    <span className="mt-4 text-secondary">Sua compra em {charge.charges.length} x de {formatValue({ value: charge.charges[0].value, currency: 'BRL' })} foi gerada e a 1ª parcela está disponível para pagamento. Para efetuar o pagamento das demais parcelas clique em "Visualizar Boletos" ou <Link to={`/i/${charge.charges[0].pub_identifier}`} target="_blank">acesse a sua fatura</Link></span>
                  }
                  {
                      (charge.charges && state.type_payment === 2) &&
                      <>
                        <span className="mt-4 text-secondary">Sua assinatura com frequência {optionsCycle[state.cycle].label.toLowerCase()} de {formatValue({ value: charge.charges[0].value, currency: 'BRL' })} foi confirmada e a 1ª parcela está disponível para pagamento.</span>
                        <br />
                        <Link to={`/i/${charge.charges[0].pub_identifier}`} target="_blank">Acesse a sua fatura</Link>
                      </>
                  }
                  {
                    charge.plug_boleto &&
                    <>
                        <h5 className="mt-4 mb-4">Para pagar com Boleto Bancário</h5>
                        <span className="mt-3">Copie a linha digitável {(!charge.charges || state.type_payment === 2) ? 'ou visualize seu boleto' : 'da 1ª parcela ou visualize os boletos deste parcelamento'}:</span>
                        <br />
                        <span className="text-secondary">{!charge.charges ? charge.plug_boleto.digitable_line : charge.charges[0].plug_boleto.digitable_line}</span>
                        <br />
                        <CopyToClipboard onCopy={() => SuccessToast({ placeholder: 'Linha digitável copiada para área de transferência!' })} text={!charge.charges ? charge.plug_boleto.digitable_line : charge.charges[0].plug_boleto.digitable_line}>
                            <Button className="mt-4 mr-2" color="success">Copiar Linha Digitável</Button>
                        </CopyToClipboard>
                        <Button className="mt-4 mr-2" color="success" outline onClick={() => handleViewPdf()}>Visualizar {(!charge.charges || state.type_payment === 2) ? 'Boleto' : 'Boletos'}</Button>
                        <br />
                        <p className="mt-5">
                            <Link to={`/i/${!charge.charges ? charge.pub_identifier : charge.charges[0].pub_identifier}`} target="_blank">Visualize a fatura </Link>
                            e tenha acesso a todas as informações
                        </p>
                    </>
                  }
                  {
                    charge.pix &&
                    <>
                        <h5 className="mt-4 mb-4">Para pagar com Pix</h5>
                        <Row>
                            <Col md={12} sm={12} xs={12} className='d-flex justify-content-center flex-column align-items-center'>
                                <span className="mt-3">Use o QR Code:</span>
                                <img width={200} src={!charge.charges ? charge.pix.qrcode : charge.charges[0].pix.qrcode } alt="QR Code" />
                                <span className="mt-3">ou use o Pix Copia E Cola:</span>
                                <br />
                                <span className="text-secondary" style={{wordBreak: 'break-all', maxWidth: '50%'}}>{!charge.charges ? charge.pix.copiaecola : charge.charges[0].pix.copiaecola}</span>
                                <CopyToClipboard onCopy={() => SuccessToast({ placeholder: 'Pix Copia e Cola foi copiada para área de transferência!' })} text={!charge.charges ? charge.pix.copiaecola : charge.charges[0].pix.copiaecola}>
                                    <Button className="mt-4 mr-2" color="success">Copiar Pix Copia e Cola</Button>
                                </CopyToClipboard>
                            </Col>
                        </Row>
                    </>
                  }
                </div>
              </div>
            </Col>
          </>
        }
      </Row>
      {
        state.payment_link_images.length > 0 &&
        <>
            <ModalDialog images={state.payment_link_images} isOpen={openDialog} setIsOpen={setOpenDialog} currentImg={currentImg} setCurrentImg={setCurrentImg} />
        </>
      }
      {
        !isMobile() &&
        <div className="text-center opacity-8 mt-3">
          Copyright &copy; WFPay Gestão Financeira {dateNow(null, 'YYYY')}
        </div>
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  loading: state.AppConfig.loading,
})

const mapDispatchToProps = (dispatch) => ({
  setLoading: (enable, text = null) => dispatch(setLoading(enable, text))
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentLinkPub);
