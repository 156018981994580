import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { setIsAuthenticated, setIsBlocked, setBalance, setUser, setUpdateUser } from "../../reducers/AppConfig";
import cx from "classnames";
import { withRouter, Route, Switch } from "react-router-dom"

import LoadingOverlay from "react-loading-overlay";
import { Loader } from "react-loaders";

import { ToastContainer } from "react-toastify";

import ResizeDetector from "react-resize-detector";

import { getInfoUser } from "../../services/auth";

import AppMain from "../../Layout/AppMain";
import PrivateRoute from "./PrivateRoute";
import RegisterPage from "../../Pages/UserPages/Register";
import LoginPage from "../../Pages/UserPages/Login";
import ForgotPasswordPage from "../../Pages/UserPages/ForgotPassword";
import RecoveryPasswordPage from "../../Pages/UserPages/RecoveryPassword";
import Charge from "../../Pages/ChargePub";
import FindCharges from "../../Pages/FindChargesPub";
import ChargeReceipt from "../Charge/components/ChargeReceipt";
import PaymentLink from "../../Pages/PaymentLinkPub";
import PaymentReceipt from "../Payment/components/PaymentReceipt";
import TransferReceipt from "../Transfer/components/TransferReceipt";
import Echo from 'laravel-echo';
import api from "../../services/api";
import { NotificationToast } from "../../Layout/AppMain/Components/Toast";
import ConfigWS from "../../config/configWS";
window.Pusher = require('pusher-js');

const Main = (props) => {
  LoadingOverlay.propTypes = undefined;
  const { user } = props
  const [users, setUsers] = useState([]);
  const userDataRef = useRef([]);
  userDataRef.current = users;

  const {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    closedSmallerSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
    loading,
    loadingText,
    loadIcon,
    updateUser,
    isAuthenticated,
    setIsAuthenticated,
    setIsBlocked,
    setBalance,
    setUpdateUser,
    setUser
  } = props;

  const refUpdateUser = useRef()
  refUpdateUser.current = updateUser

  const useHere = () => {
    let allUsers = [...userDataRef.current];
      for (let i in allUsers) {
        if (allUsers[i].token === user.token) {
          allUsers[i].blocked = false;
        } else {
          allUsers[i].blocked = true;
        }
      }
      setIsBlocked(false);
      setUsers([...allUsers]);

      window.Echo.join(`user_session.${user.id}`).whisper('useHere', user)
  }

  const handleClickNotification = (notification) => {
    if (notification.url) {
        window.open(notification.url)
    }

    if (!notification.date_viewed) {
      api.post(`notifications/${notification.id}/viewed`)
      .then(response => {
        setUser({
          ...user,
          notifications: user.notifications.map(not => {
            if (not.id === notification.id) {
              return {
                ...response.data
              }
            }

            return not
          })
        })
      })
      .catch(e => {
        console.log(e)
      })
    }
  }

  const handleNotify = async (n) => {
    if (document.visibilityState === 'visible') {
      NotificationToast({ title: n.title, message: n.message, onClick: () => handleClickNotification(n) })
    }

    if (Notification.permission === 'granted' && document.visibilityState === 'hidden') {
      const notification = new Notification(n.title, {
        body: n.message
      })

      notification.onclick = () => handleClickNotification(n)
    }
  }

  const requestPermissionNotification = async () => {
    if (!Notification) {
      console.log('Esse browser não suporta notificações desktop');
    } else {
      if (!['denied', 'granted'].includes(Notification.permission)) {
        // Pede ao usuário para utilizar a Notificação Desktop
        await Notification.requestPermission();
      }
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        let response = await api.get('users/me');
        let data = {
          ...getInfoUser(),
          ...response.data
        }
        localStorage.removeItem('@userInfo');
        localStorage.setItem('@userInfo', JSON.stringify(data));
        setIsAuthenticated(true);
        setUser(data);

        api.get('organizations/taxes')
        .then(response => {
          if (!!response.data) {
            localStorage.removeItem('@organizationTaxes');
            localStorage.setItem('@organizationTaxes', JSON.stringify(response.data));
          }
        })
        .catch(e => console.log(e))
      } catch (e) {
        console.log(e);
        setIsAuthenticated(false);
      }
    }

    getData();
  }, [updateUser]);

  useEffect(() => {
    if (user.id === undefined) {
      return;
    }

    window.Echo = new Echo(ConfigWS);
    window.Echo.join(`user_session.${user.id}`)
    .here(users => {
      setUsers(users);
    })
    .joining(u => {
      let allUsers = [...userDataRef.current];
      allUsers = allUsers.filter(u2 => u2.token !== u.id);
      for (let i in allUsers) {
        allUsers[i].blocked = true;
      }
      allUsers.push({...u, blocked: false});
      for (let i in allUsers) {
        if (allUsers[i].token === user.token) {
          setIsBlocked(allUsers[i].blocked);
        }
      }
      setUsers([...allUsers]);
    })
    .leaving(u => {
      let allUsers = [...userDataRef.current];
      allUsers = allUsers.filter(u2 => u2.token !== u.token);
      setUsers(allUsers)
    })
    .listenForWhisper('useHere', u => {
      let allUsers = [...userDataRef.current];
      allUsers = allUsers.filter(u2 => u2.token !== u.token);
      for (let i in allUsers) {
        allUsers[i].blocked = true;
      }
      allUsers.push({...u, blocked: false});
      for (let i in allUsers) {
        if (allUsers[i].token === user.token) {
          setIsBlocked(allUsers[i].blocked);
        }
      }
      setUsers([...allUsers]);
    })
    .listen('NewNotification', r => {
        setUpdateUser(!refUpdateUser.current)
        handleNotify(r.notification)
        api.post(`notifications/${r.notification.id}/received`)
        .catch(e => {
        console.log(e)
        })
    });

    window.Echo.private(`company_session.${user.company_id}`)
    .listen('CompanyUpdated', e => {
        setUpdateUser(!refUpdateUser.current)
    });
  }, [user.id, user.token]);

  useEffect(() => {
    if (isAuthenticated) {
      requestPermissionNotification()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (user && user.company) {
      setBalance(user.company.balance || 0)
    }
  }, [user.company]);

  return (
    <ResizeDetector
      handleWidth
      render={({ width }) => (
        <>
        <LoadingOverlay styles={{
          overlay: (base) => ({
            ...base,
            position: 'fixed',
            zIndex: '999999999999'
          })
        }} tag="div" active={loading}
          spinner={
            <>
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader active type={loadIcon} />
                  </div>
                  {
                    loadingText &&
                    <h6 className="mt-5">
                      {loadingText}
                    </h6>
                  }
                </div>
              </div>
            </>
          }>
          <div
            className={cx(
              "app-container app-theme-" + colorScheme,
              { "fixed-header": enableFixedHeader },
              { "fixed-sidebar": enableFixedSidebar || width < 1250 },
              { "fixed-footer": enableFixedFooter },
              { "closed-sidebar": enableClosedSidebar || width < 1250 },
              {
                "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
              },
              { "sidebar-mobile-open": enableMobileMenu },
              { "body-tabs-shadow-btn": enablePageTabsAlt }
            )}>
              <Switch>
                <Route exact path="/register" render={(props) =>
                  <RegisterPage {...props} />
                }/>
                <Route exact path="/login" render={(props) =>
                  <LoginPage {...props} />
                }/>
                <Route exact path="/forgot-password" render={(props) =>
                  <ForgotPasswordPage {...props} />
                } />
                <Route exact path="/recovery-password/:user_id/:token" render={(props) =>
                  <RecoveryPasswordPage {...props} />
                } />
                <Route exact path="/origem-cobrancas" render={(props) =>
                  <FindCharges {...props} />
                } />
                <Route exact path="/c/:id" render={(props) =>
                  <PaymentLink {...props} />
                } />
                <Route exact path="/i/:id" render={(props) =>
                  <Charge {...props} />
                } />
                <Route exact path="/i/:id/comprovante" render={(props) =>
                  <ChargeReceipt {...props} />
                } />
                <Route exact path="/p/:id/comprovante" render={(props) =>
                  <PaymentReceipt {...props} />
                } />
                <Route exact path="/t/:id/comprovante" render={(props) =>
                  <TransferReceipt {...props} />
                } />
                <PrivateRoute path="/"
                  component={AppMain}
                  width={width}
                  useHere={useHere}
                  notifications={user.notifications}
                />
              </Switch>
          </div>
          </LoadingOverlay>
          <ToastContainer />
          </>
      )}
    />
  );
}

const mapStateToProps = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
  loading: state.AppConfig.loading,
  loadingText: state.AppConfig.loadingText,
  loadIcon: state.AppConfig.loadIcon,
  updateUser: state.AppConfig.updateUser,
  isAuthenticated: state.AppConfig.isAuthenticated,
  isBlocked: state.AppConfig.isBlocked,
  user: state.AppConfig.user
});

const mapDispatchToProps = (dispatch) => ({
  setIsAuthenticated: (enable => dispatch(setIsAuthenticated(enable))),
  setIsBlocked: (enable => dispatch(setIsBlocked(enable))),
  setBalance: (enable => dispatch(setBalance(enable))),
  setUser: (user) => dispatch(setUser(user)),
  setUpdateUser: (enable) => dispatch(setUpdateUser(enable))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));

