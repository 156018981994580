import React from 'react';

export function AddScriptMovidesk() {
  if (document.body.getElementsByClassName('ChatUrl').length === 0) {
    // Adiciona o script com o mdChatClient
    const scriptConfig = document.createElement('script');
    scriptConfig.type = 'text/javascript';
    scriptConfig.className = 'mdChatClient';
    scriptConfig.async = true;
    scriptConfig.textContent = 'var mdChatClient="BEC2EDBA876A438D87D274676E4A2BC4";';
    document.body.appendChild(scriptConfig);

    // Adiciona o script contendo a URL do chat
    const scriptUrl = document.createElement('script');
    scriptUrl.src = 'https://chat.movidesk.com/Scripts/chat-widget.min.js';
    scriptUrl.className = 'ChatUrl';
    scriptConfig.async = true;
    document.body.appendChild(scriptUrl);
  }
}

function ChatMovidesk() {
  return (
    <div className='ChatMovidesk'>
      {
        AddScriptMovidesk()
      }
    </div>
  )
}

export default ChatMovidesk;
