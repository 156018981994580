import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setLoading } from "../../../reducers/AppConfig";

import { RiEyeCloseLine } from 'react-icons/ri';
import { ImEye } from 'react-icons/im'
import { Col, Row, Button, FormGroup, Label, Input, InputGroupAddon, InputGroup, InputGroupText, CustomInput, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
import { ErrorToast } from "../../../Layout/AppMain/Components/Toast";

import api from "../../../services/api";
import { formatPassword, dateNow, isMobile } from 'nfutils';
import PasswordForce from "../../../Layout/AppMain/Components/PasswordForce";
import validator from 'validator'
// Layout

const Register = ({ history, setLoading, isAuthenticated }) => {
    const [validation, setValidation] = useState({})
    const [showPasswordForce, setShowPasswordForce] = useState(false)
    const [levelPassword, setLevelPassword] = useState(0);
    const [state, setState] = useState({
        name: '',
        email: '',
        password: '',
        confirm_password: '',
        accept_terms: false
    });

    const [viewPassword, setViewPassword] = useState(false);
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    const handleChange = (value, prop) => {
        if (prop === 'password') {
            value = formatPassword(value);
        }

        setState({
            ...state,
            [prop]: value
        })
    }

    const handleValidate = input => {
        let invalid = {}
        if (!!input) {
            invalid = {...validation}
            invalid[input] = false
        }

        if (!validator.isEmail(state.email) && (!input || input === 'email')) {
            invalid.email = true
        }
        if ((!state.password || !state.password.trim() || levelPassword < 6) && (!input || input === 'password')) {
            invalid.password = true
        }
        if (state.password !== state.confirm_password && (!input || input === 'confirm_password')) {
            invalid.confirm_password = true
        }
        if (!state.accept_terms && !input) {
            invalid.accept_terms = true
        }

        setValidation(invalid)

        if (Object.keys(invalid).length > 0 && !input) {
            ErrorToast({ placeholder: 'Os campos marcados com * são obrigatórios' })
            return false
        }

        return true;
    }

    const handleFormData = () => {
        return {
            ...state,
        }
    }

    const handleSubmit = async () => {
        if (!handleValidate()) {
            return;
        }

        try {
            setLoading(true, 'Cadastrando conta...')

            await api.post('register', handleFormData());

            api.get('../sanctum/csrf-cookie').then(r => {
                setLoading(true, 'Realizando autenticação...');
                api.post('login', handleFormData()).then(response => {
                    setLoading(true, 'Redirecionando...');
                    localStorage.setItem('@userInfo', JSON.stringify(response.data));
                    setLoading(false);
                    window.location.assign('/');
                }).catch(e => {
                    console.log(e);
                    window.location.assign('/');
                });
            }).catch(e => {
                console.log(e);
                window.location.assign('/');
            })
        } catch (e) {
            console.log(e)
            let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar cadastrar a conta, tente novamente mais tarde!'
            ErrorToast({  placeholder: msg})
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
      if (isAuthenticated) {
        history.push('/');
      }
    }, [isAuthenticated])

    return (
        <>
          <div className={`${isMobile() ? 'h-100' : 'he-100'} bg-plum-plate bg-animation`}>
            <div className={`d-flex ${isMobile() ? 'h-100' : 'he-100'} justify-content-center align-items-center`}>
              <Col md="8" className="mx-auto app-login-box">
                <div className="app-logo-inverse mx-auto mb-3" style={{ height: '8rem' }} />
                <div className="modal-dialog w-100 mx-auto">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="h5 modal-title text-center">
                        <h4 className="mt-2">
                          <div>Bem vindo,</div>
                          <span>Leva apenas alguns segundos para criar a sua conta</span>
                        </h4>
                      </div>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label htmlFor="name">Nome</Label>
                                    <Input type="name" value={state.name} onChange={({ target: { value } }) => handleChange(value, 'name')} placeholder="Seu nome e sobrenome..." onKeyUp={handleKeyUp} />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label><span className="text-danger">*</span> E-mail</Label>
                                    <Input invalid={validation.email} type="email" value={state.email} onChange={({ target: { value } }) => handleChange(value, 'email')} placeholder="Digite seu melhor e-mail aqui..." autoComplete="new-username" onBlur={() => handleValidate('email')} onKeyUp={handleKeyUp} />
                                    <FormFeedback>Informe um e-mail válido</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label><span className="text-danger">*</span> Senha</Label>
                                    <InputGroup>
                                    <Input invalid={validation.password} type={viewPassword ? 'text' : 'password'} id="password" placeholder="Digite sua senha..."  value={state.password} onChange={({ target: {value} }) => handleChange(value, 'password')} onFocus={() => setShowPasswordForce(true)} onBlur={() => {handleValidate('password'); setShowPasswordForce(false)}} autoComplete="new-password" onKeyUp={handleKeyUp} />
                                    <InputGroupAddon id="view_password" style={{cursor: 'pointer'}} addonType="append" onClick={() => setViewPassword(!viewPassword)}>
                                        <InputGroupText>
                                            {viewPassword ? <ImEye /> : <RiEyeCloseLine />}
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <FormFeedback>Informe uma senha válida</FormFeedback>
                                    </InputGroup>
                                    {
                                    showPasswordForce &&
                                    <>
                                        <br />
                                        <PasswordForce password={state.password} onLevel={value => setLevelPassword(value)} />
                                    </>
                                    }
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label><span className="text-danger">*</span> Confirme a Senha</Label>
                                    <InputGroup>
                                    <Input invalid={validation.confirm_password} type={viewConfirmPassword ? 'text' : 'password'} id="confirm_password" placeholder="Confirme a senha digitada..."  value={state.confirm_password} onChange={({ target: {value} }) => handleChange(value, 'confirm_password')} onBlur={() => handleValidate('confirm_password')} autoComplete="new-password" onKeyUp={handleKeyUp} />
                                    <InputGroupAddon id="view_confirm_password" style={{cursor: 'pointer'}} addonType="append" onClick={() => setViewConfirmPassword(!viewConfirmPassword)}>
                                        <InputGroupText>
                                            {viewConfirmPassword ? <ImEye /> : <RiEyeCloseLine />}
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <FormFeedback>As senhas não coincidem</FormFeedback>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <span>
                                <InputGroup>
                                <CustomInput invalid={validation.accept_terms} type="switch" id="accept_terms" checked={state.accept_terms} onChange={({ target: { checked } }) => handleChange(checked, 'accept_terms')} label="Aceitar nossos" />
                                &nbsp;
                                <a href="http://wfpay.com.br/index.php/termos-condicoes/" target="_blank" rel="noopener noreferrer">
                                    Termos e Condições
                                </a>
                                </InputGroup>
                            </span>
                        </FormGroup>
                      <div className="divider" />
                      <h6 className="mt-3">
                        Já possui uma conta?{" "}
                        <Link id="login" to="/login">
                            Entrar
                        </Link>
                      </h6>
                      <div className="d-flex align-items-center">
                            <div className="ml-auto">
                                <Button type="button" color="primary" size="lg" onClick={handleSubmit}>
                                Criar Conta
                                </Button>
                            </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center text-white opacity-8 mt-3">
                  Copyright &copy; WFPay Gestão Financeira {dateNow(null, 'YYYY')}
                </div>
              </Col>
            </div>
          </div>
        </>
      );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.AppConfig.isAuthenticated,
})

const mapDispatchToProps = (dispatch) => ({
  setLoading: (enable, text = null) => dispatch(setLoading(enable, text))
})

export default connect(mapStateToProps, mapDispatchToProps)(Register);
