const menuPermissions = () => {
  return {
    '/#/clientes': [1, 2, 3],
    '/#/cobrancas': [1, 2, 3],
    '/#/cobrancas/avulsas': [1, 2, 3],
    '/#/parcelamentos': [1, 2, 3],
    '/#/assinaturas': [1, 2, 3],
    '/#/links-de-pagamentos': [1, 2, 3],
    '/#/extrato': [1, 2],
    '/#/transferencias': [1, 2],
    '/#/pagamentos': [1, 2],
    '/#/configuracoes': [1, 2],
    '/#/serasa/consultas': [1, 2]
  }
}

export default menuPermissions;

