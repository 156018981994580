import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { Button, Col, Collapse, CustomInput, FormFeedback, FormGroup, Label, ListGroup, ListGroupItem, Row, UncontrolledTooltip } from 'reactstrap';
import InputMask from '../InputMask';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { FaChevronDown, FaInfoCircle } from 'react-icons/fa';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'

import api from '../../../../services/api';
import { ErrorToast, SuccessToast } from '../Toast';
import { setLoading } from '../../../../reducers/AppConfig'
import { formatDate, onlyNumber } from 'nfutils';

import './styles.css'

const CriticalEvents = ({ setLoading, user, sweetAlert }) => {
    const [validation, setValidation] = useState({});
    const [events, setEvents] = useState([]);
    const [state, setState] = useState({
        google2fa_token: ''
    })
    const [showEvents, setShowEvents] = useState(false);
    const [totalChecked, setTotalChecked] = useState(0);
    const [showToken, setShowToken] = useState(false)

    const handleClearFields = () => {
        setState({google2fa_token: ''})
        setShowToken(false);
    }

    const handleChange = (value, position, prop) => {
        let newEvents = [...events]
        newEvents[position][prop] = value

        if (prop === 'checked') {
            newEvents[position].data = newEvents[position].data.map(item => {
                return {
                    ...item,
                    checked: value
                }
            })
        }

        setEvents(newEvents);
    }

    const handleChangeItem = (value, position, key, prop) => {
        let newEvents = [...events]
        newEvents[position].data[key][prop] = value

        setEvents([...newEvents]);
    }

    const handleChangeState = (value, prop) => {
        setState({ ...state, [prop]: value })
    }

    const handleKeyUp = e => {
        if (e.key === 'Enter') {
            handleEffective()
        }
    }

    const handleDescription = (item) => {
        return item.description || `Solicitada em ${formatDate({ value: item.created_at.replace(' ', 'T'), location: 'Br', format: 'DD/MM/YYYY [às] HH:mm' })}`
    }

    const handleValidate = input => {
        let invalid = {}
        if (!!input) {
            invalid = {...validation}
            invalid[input] = false
        }

        if (onlyNumber(state.google2fa_token).length < 6 && (!input || input === 'google2fa_token')) {
            invalid.google2fa_token = true
        }

        setValidation(invalid)

        if (Object.keys(invalid).length > 0 && !input) {
            ErrorToast({ placeholder: 'Os campos marcados com * são obrigatórios' })
            return false
        }

        return true
    }

    const handleFormData = () => {
        let ids = [];
        events.forEach(e => {
            e.data.forEach(item => {
                if (item.checked) {
                    ids.push(item.id)
                }
            })
        })

        return {
            ids,
            google2fa_token: onlyNumber(state.google2fa_token)
        }
    }

    const handleRemoveCheckeds = () => {
        let newEvents = [...events]
        newEvents = newEvents.filter(e => {
            let data = e.data.filter(item => !item.checked)

            return data.length !== 0
        }).map(e => {
            return {
                ...e,
                data: e.data.filter(item => !item.checked)
            }
        })

        setEvents(newEvents)
    }

    const handleCancel = async () => {
        try {
            setLoading(true, 'Excluindo eventos críticos...')
            await api.post('critical_events/exclude', handleFormData())
            SuccessToast({ placeholder: 'Eventos excluídos com sucesso' })
            setShowEvents(false)
            handleRemoveCheckeds()
        } catch (e) {
            console.log(e)
            let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar excluir os eventos, tente novamente mais tarde'
            ErrorToast({ placeholder: msg })
        } finally {
            setLoading(false)
        }
    }

    const handleEffective = async () => {
        if (!handleValidate()) {
            return
        }

        try {
            setLoading(true, 'Concluindo eventos...')
            let response = await api.post('critical_events/effective', handleFormData())
            if (response.status === 204) {
                SuccessToast({ placeholder: 'Conta encerrada com sucesso!' })
                window.location.assign('/');
            } else {
                SuccessToast({ placeholder: 'Eventos concluídos com sucesso' })
                setShowEvents(false)
                handleClearFields()
                if (response.data.errors && response.data.errors.length > 0) {
                    sweetAlert.fire({
                        title: <p>Alguns eventos não puderam ser concluídos</p>,
                        html: response.data.errors.join('<br /><br />'),
                        icon: 'warning',
                    })
                } else {
                    handleRemoveCheckeds()
                }
            }
        } catch (e) {
            console.log(e)
            if (e.errors && e.errors.length > 1) {
                sweetAlert.fire({
                    title: <p>Falha ao concluir eventos</p>,
                    html: e.errors.join('<br /><br />'),
                    icon: 'error',
                })
            } else {
                let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar concluir os eventos, tente novamente mais tarde!'
                ErrorToast({ placeholder: msg })
            }
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setEvents([...user.company.critical_events.map(e => {
            return {
                ...e,
                checked: true,
                openCollapse: false,
                data: e.data.map(item => {
                    return {
                        ...item,
                        checked: true
                    }
                })
            }
        })])
    }, [user])

    useEffect(() => {
        let total = 0
        events.forEach(e => {
            e.data.forEach(item => {
                if (item.checked) {
                    total ++;
                }
            })
        })

        setTotalChecked(total);
    }, [events])

    return (
        <>
            <div className={"critical-events-container " + (showEvents ? 'is-open' : '')}>
                <Button className="btn-open-critical-events" id="btnOpenEvents" color="warning" onClick={() => setShowEvents(!showEvents)}>
                    <FontAwesomeIcon icon={faExclamationTriangle} color="#573a04" fixedWidth={false} size="2x"/>
                </Button>
                <UncontrolledTooltip placement="left" target={'btnOpenEvents'}>
                    Visualizar eventos críticos pendentes
                </UncontrolledTooltip>
                <div className="critical-events-inner">
                    <PerfectScrollbar>
                        <div className="critical-events__options-wrapper">
                            <h3 className="critical-events_heading"><b>Eventos críticos pendentes</b></h3>
                            <Collapse isOpen={!showToken}>
                                <div className="p-3">
                                    <ListGroup>
                                        {
                                            events.map((e, key) => {
                                                return <ListGroupItem key={key}>
                                                    <div className="widget-content p-0">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left">
                                                                <CustomInput type="switch" id={`checked_event_type_${key}`} label="" checked={e.checked} onChange={({target: {checked}}) => handleChange(checked, key, 'checked')} inlist="true" style={{ zIndex: 99 }}/>
                                                            </div>
                                                            <div className="widget-content-left" style={{ cursor: 'pointer' }} onClick={() => handleChange(!e.openCollapse, key, 'openCollapse')}>
                                                                <div className="widget-heading">
                                                                    {e.type_description} ({e.data.length})
                                                                    <span className="ml-2">
                                                                        <FaChevronDown className={e.openCollapse ? 'rotate-180' : ''} />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Collapse isOpen={e.openCollapse} className="pl-3">
                                                        {
                                                            e.data.map((item, index) => {
                                                                return <div key={index} className="widget-content">
                                                                    <div className="widget-content-wrapper">
                                                                        <div className="widget-content-left">
                                                                            <CustomInput type="switch" id={`event_${key}_${index}`} label="" checked={item.checked} onChange={({target: {checked}}) => handleChangeItem(checked, key, index, 'checked')} inlist="true" style={{ zIndex: 99 }}/>
                                                                        </div>
                                                                        <div className="widget-content-left">
                                                                            <div className="widget-heading">
                                                                                {handleDescription(item)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </Collapse>
                                                </ListGroupItem>
                                            })
                                        }
                                    </ListGroup>
                                </div>
                                <div className="p-3">
                                    <Button disabled={totalChecked === 0} className="mr-2 mt-2" color="danger" onClick={handleCancel}>Excluir ({totalChecked})</Button>
                                    <Button disabled={totalChecked === 0} className="mr-2 mt-2" color="success" onClick={() => setShowToken(true)}>Validar com Google Authenticator ({totalChecked})</Button>
                                </div>
                            </Collapse>
                            <Collapse isOpen={showToken}>
                                <div className="p-3">
                                    <h6>Informe abaixo o token gerado pelo aplicativo do Google Authenticator e clique em 'Enviar'</h6>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup className="mt-3">
                                                <Label for="google2fa_token">Token de Autorização <span className="text-danger">*</span></Label>
                                                <InputMask inputMode="numeric" invalid={validation.google2fa_token} mask="999 999" id="google2fa_token" onChange={({ target: { value } }) => handleChangeState(value, 'google2fa_token')} title="Token gerado pelo aplicativo Google Authenticator" value={state.google2fa_token} onBlur={() => handleValidate('google2fa_token')} onKeyUp={handleKeyUp} />
                                                <FormFeedback>Informe o token de autorização de 6 dígitos gerado pelo aplicativo Google Authenticator</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <small className="text-secondary"><FaInfoCircle />&nbsp;Caso necessite você pode configurar seu dispositivo para gerar os tokens com o google authenticator em "Configurações da conta / Configurações / Google Authenticator".</small>
                                    <Button disabled={totalChecked === 0} className="mr-2 mt-2" color="light" onClick={() => setShowToken(false)}>Voltar</Button>
                                    <Button disabled={totalChecked === 0} className="mr-2 mt-2" color="success" onClick={handleEffective}>Enviar</Button>
                                </div>
                            </Collapse>
                        </div>
                    </PerfectScrollbar>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.AppConfig.user,
    sweetAlert: state.AppConfig.sweetAlert
})

const mapDispatchToProps = (dispatch) => ({
    setLoading: (enable, text) => dispatch(setLoading(enable, text))
})

export default connect(mapStateToProps, mapDispatchToProps)(CriticalEvents)
