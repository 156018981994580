import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';

import { FaArrowDown, FaBarcode, FaCaretLeft, FaCircle, FaRegCheckCircle, FaRegClock, FaRegCreditCard, FaRegMoneyBillAlt, FaRegTimesCircle } from 'react-icons/fa'
import { FaPix } from "react-icons/fa6";
import { Alert, Button, Col, FormFeedback, FormGroup, Input, InputGroup, Label, Row, UncontrolledTooltip } from 'reactstrap'
import AutoComplete from '../../Layout/AppMain/Components/AutoComplete'
import InputMask from '../../Layout/AppMain/Components/InputMask'
import { ErrorToast, SuccessToast } from '../../Layout/AppMain/Components/Toast'
import { CopyToClipboard } from "react-copy-to-clipboard";
import LoadingIcon, { ModalLoading } from '../../Layout/AppMain/Components/LoadingIcon';
import DataBanner from '../Company/components/DataBanner';
import currency from 'currency.js'

import api from '../../services/api'
import buscaCep from '../../services/apiCep'
import validator from 'validator'
import Echo from 'laravel-echo';
import ConfigWS from '../../config/configWS';
import { setLoading } from '../../reducers/AppConfig';
import { dateNow, diffDate, formatCpfCnpj, formatDate, formatValue, isCpfCnpj, isMobile, onlyNumber } from 'nfutils'
import CardInputToken from './components/CardInputToken';
import { CardIcons } from '../../Layout/AppMain/Components/CardIcons/Index';
import { cardsMasks } from '../../services/cardsMasks';
import Switch from 'react-switch';
const ChargePub = ({ history, match, setLoading }) => {
  const { id } = match.params
  const [showInfoCustomer, setShowInfoCustomer] = useState(false);
  const [updateAddress, setUpdateAddress] = useState(false);
  const [validation, setValidation] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [inRequest, setInRequest] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState({
    active: false,
    hasShow: false
  });
  const [boletoLoading, setBoletoLoading] = useState(false);
  const [ state, setState ] = useState({
    payment_by: 1,
    company: {},
    customer: {},
    days_due_date: 0,
    parcel_number: 0,
    pending_charge: null,
    subscription_resume: [],
    card: {},
    show_all_installments: false,
  });

  const handleChange = (value, prop) => {
    let newState = {...state}
    newState[prop] = value

    if(prop === 'payment_by' && value === 1 && state.plug_boleto === null && !confirmAlert.hasShow) {
        setConfirmAlert({
            active: true,
            hasShow: true
        })
    }

    if (prop === 'payment_by' && showInfoCustomer) {
      setShowInfoCustomer(!showInfoCustomer)
    }

    setState(newState)
  }

  const handleChangeCard = (value, prop) => {
    let card = {...state.card}
    card[prop] = value

    setState({ ...state, card })
  }

  const handleChangeCustomer = (value, prop) => {
    let customer = {...state.customer}
    customer[prop] = value

    if (prop === 'cep' && onlyNumber(value).length === 8) {
      setUpdateAddress(!updateAddress)
    }
    if (prop === 'state') {
      customer.city = null
    }
    if (prop === 'city' && value) {
      customer.state = {
        value: value.data.state.id,
        label: value.data.state.sigla,
        data: value.data.state
      }
    }

    setState({ ...state, customer })
  }

  const handleBack = () => {
    history.push('/');
  }

  const handleCharge = (pub_id, blank = true) => {
    if (blank) {
      window.open(`/#/i/${pub_id}`)
    } else {
      history.push(`/i/${pub_id}`)
    }
  }

  const handleShowInfoCustomer = () => {
    if (!handleValidate(1)) {
      return
    }

    setShowInfoCustomer(true)
  }

  /**
   * Função responsável por válidar os campos do formulário
   *
   * @param level Número inteiro indicando o tipo de validação
   *              - 1 = Valida os campos da tela de cartão
   *              - 2 = Valida os campos da tela de informações do comprador
   *              - 3 = Valida um campo específico
   *              - 4 = Valida todos os campos do formulário
   * @param input Indica o campo que deve ser válidado (Quando level = 3)
   *
   * @return boolean
   */
  const handleValidate = (level, input = null) => {
    let invalid = {}
    if (level === 3) {
      invalid = {...validation}
      invalid[input] = false
    }

    if ([1, 3, 4].includes(level)) {
      if ((!state.card.number || !state.card.number.trim()) && (input === 'card_number' || !input)) {
        invalid.card_number = true
      }
      if ((!state.card.name || !state.card.name.trim() || state.card.name.length < 3 || (state.card.name.split(' ')).length < 2) && (input === 'card_name' || !input)) {
        invalid.card_name = true
      }
      if ((!state.card.month || !state.card.month.trim() || state.card.month.length !== 2 || state.card.month < 1 || state.card.month > 12) && (input === 'card_month' || !input)) {
        invalid.card_month = true
      }
      if ((!state.card.year || !state.card.year.trim() || onlyNumber(state.card.year).length !== 4 || state.card.year < dateNow(null, 'YYYY')) && (input === 'card_year' || !input)) {
        invalid.card_year = true
      }
      if ((!state.card.cvv || !state.card.cvv.trim() || state.card.cvv.length < 3 || state.card.cvv.length > 4) && (input === 'card_cvv' || !input)) {
        invalid.card_cvv = true
      }
    }
    if ([2, 3, 4].includes(level)) {
      if ((!state.customer.name || !state.customer.name.trim() || state.customer.name.length < 3) && (input === 'customer_name' || !input)) {
        invalid.customer_name = true
      }
      if (!validator.isEmail(state.customer.email) && (input === 'customer_email' || !input)) {
        invalid.customer_email = true
      }
      if (!isCpfCnpj(state.customer.cpfcnpj) && (input === 'customer_cpfcnpj' || !input)) {
        invalid.customer_cpfcnpj = true
      }
      if ((!state.customer.cellphone || !state.customer.cellphone.trim() || onlyNumber(state.customer.cellphone).length !== 11) && (input === 'customer_cellphone' || !input)) {
        invalid.customer_cellphone = true
      }
      if ((!state.customer.cep || !state.customer.cep.trim() || onlyNumber(state.customer.cep).length !== 8) && (input === 'customer_cep' || !input)) {
        invalid.customer_cep = true
      }
      if ((!state.customer.address || !state.customer.address.trim() || state.customer.address.length < 10 || state.customer.address.length > 200) && (input === 'customer_address' || !input)) {
        invalid.customer_address = true
      }
      if ((!state.customer.number || !state.customer.number.trim() || state.customer.number.length > 10) && (input === 'customer_number' || !input)) {
        invalid.customer_number = true
      }
      if ((!state.customer.neighborhood || !state.customer.neighborhood.trim() || state.customer.neighborhood.length < 5 || state.customer.neighborhood.length > 60) && (input === 'customer_neighborhood' || !input)) {
        invalid.customer_neighborhood = true
      }
      if (state.customer.complement?.length > 100 && (input === 'customer_complement' || !input)) {
        invalid.customer_complement = true
      }
      if (!state.customer.state && (input === 'customer_state' || !input)) {
        invalid.customer_state = true
      }
      if (!state.customer.city && (input === 'customer_city' || !input)) {
        invalid.customer_city = true
      }
    }

    setValidation(invalid)
    if (Object.keys(invalid).length > 0) {
      return false
    }

    return true
  }

  const handleSubmitCard = async () => {
    if (!handleValidate(4)) {
      return
    }

    setInRequest(true)

    await api.post(`i/${id}/credit-card`, {
        customer: {
            name: state.customer.name,
            email: state.customer.email,
            phone: onlyNumber(state.customer.phone),
            cellphone: onlyNumber(state.customer.cellphone),
            cep: onlyNumber(state.customer.cep),
            address: state.customer.address,
            number: state.customer.number,
            neighborhood: state.customer.neighborhood,
            complement: state.customer.complement,
            city_id: state.customer.city.value,
            state_id: state.customer.state.value
        },
        card: {
            token: state.card.token,
            brand: state.card.bandeira,
            name: state.card.name,
            month: onlyNumber(state.card.month),
            year: onlyNumber(state.card.year),
            cvv: onlyNumber(state.card.cvv),
            recurrence: !!state.card.recurrence
        }
    }).then(response => {
        SuccessToast({ placeholder: 'Pagamento efetuado com sucesso!' })
        setTimeout(() => window.location.reload(), 1000)
    }).catch(e => {
        console.log(e)
        let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao efetuar o pagamento, tente novamente mais tarde'
        ErrorToast({ placeholder: msg })
    }).finally(() => {
        setInRequest(false)
    });
  }

  const getData = async () => {
    try {
      setIsLoading(true);
      let response = await api.get(`i/${id}`)
      let days_due_date = diffDate({ dateOne: response.data.due_date, dateTwo: dateNow(null, 'YYYY-MM-DD'), type: 'days' })
      let parcel_number = 0
      let pending_charge = null;
      let subscription_resume = [];
      if(response.data.plug_boleto){
          setBoletoLoading(false)
      }

      if (response.data.installment) {
        response.data.installment.charges.forEach((charge, position) => {
          if (charge.pub_identifier === id) {
            parcel_number = (position + 1)
          }
        })

        pending_charge = response.data.installment.charges.filter((charge, position) => {
          return (charge.pub_identifier !== id && position < (parcel_number - 1) && charge.status === 0);
        })

        pending_charge = pending_charge.length > 0 ? pending_charge[0].pub_identifier : null;
      } else if (response.data.subscription) {
        response.data.subscription.charges.forEach((charge, position) => {
          if (charge.pub_identifier === id) {
            parcel_number = (position + 1)
          }
        })

        pending_charge = response.data.subscription.charges.filter((charge, position) => {
          return (charge.pub_identifier !== id && position < (parcel_number - 1) && charge.status === 0);
        })

        pending_charge = pending_charge.length > 0 ? pending_charge[0].pub_identifier : null;

        subscription_resume = response.data.subscription.charges.filter(charge => {
          return (charge.status === 0 && charge.due_date < dateNow(null, 'YYYY-MM-DD'));
        })
      }
      setState({
        ...state,
        ...response.data,
        payment_by: response.data.type_payment === 3 ?
          (response?.data?.pix !== null ? 4 : 1) : response.data.type_payment,
        customer: {
          ...response.data.customer,
          city: response.data.customer.city ? {
            value: response.data.customer.city.id,
            label: response.data.customer.city.name,
            data: response.data.customer.city
          } : null,
          state: response.data.customer.state ? {
            value: response.data.customer.state.id,
            label: response.data.customer.state.name,
            data: response.data.customer.state
          } : null
        },
        days_due_date,
        parcel_number,
        pending_charge,
        subscription_resume
      })
    } catch (e) {
      console.log(e)
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao buscar as informações da fatura, tente novamente mais tarde'
      ErrorToast({ placeholder: msg })
      handleBack()
    } finally {
      setIsLoading(false)
    }
  }

  const sendInvoiceBoletoRequest = async () => {
    if(state?.plug_boleto === null) {
        try {
            await api.post(`i/${id}/boleto`)
            SuccessToast({ placeholder: 'Boleto solicitado com sucesso!' })
            setConfirmAlert({
                ...confirmAlert,
                active: false,
            })
            setBoletoLoading(true)
            setState({
                ...state,
                type_payment: 1,
            })
        } catch (e) {
            console.log(e)
            let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao solicitar o boleto, tente novamente mais tarde'
            ErrorToast({ placeholder: msg })
        }
    }
  }

  useEffect(() => {
    if (typeof id !== 'undefined') {
      getData();

      if (!window.Echo) {
        window.Echo = new Echo(ConfigWS);
      }

      window.Echo.channel(`charge_pub.${id}`)
      .listen('InvoiceGenerated', _ => {
        getData();
      });
    }
  }, [id])

  useEffect(() => {
    if (state.customer && onlyNumber(state.customer.cep).length === 8) {
      setLoading(true)
      buscaCep(state.customer.cep)
      .then(response => {
        setState({ ...state, customer: {
          ...state.customer,
          ...response,
          number: '',
          complement: '',
          city: {
            value: response.city_id,
            label: response.city
          },
          state: {
            value: response.state_id,
            label: response.uf
          },
        } })
      })
      .catch(e => {
        console.log(e)
      })
      .finally(_ => {
        setLoading(false)
      })
    }
  }, [updateAddress])

  return (
    <>
      <div className={`bg-status-payment${state.status === 0 && state.days_due_date < 0 ? ' bg-status-payment-danger' : ([1, 2].includes(state.status) ? ' bg-status-payment-success' : '')}`}></div>
      <div className="container-bill">
        <div className="d-flex justify-content-center align-items-center">
          <div className="mx-auto app-login-box">
            <div className="w-100 mx-auto">
              {
                isLoading
                ?
                <ModalLoading />
                :
                <div className="modal-content">
                    <DataBanner state={state.company || {}} />
                    <div className="modal-body">
                    <div className="mt-3 d-flex align-items-center data-box" style={{ padding: '8px 16px' }}>
                        {
                        state.status === 0 && state.days_due_date >= 0 &&
                        <>
                            <FaCircle size={15} color="var(--warning)" />
                            &nbsp;&nbsp;Aguardando Pagamento
                        </>
                        }
                        {
                        state.status === 0 && state.days_due_date < 0 &&
                        <>
                            <FaCircle size={15} color="var(--danger)" />
                            &nbsp;&nbsp;Cobrança vencida
                        </>
                        }
                        {
                        [1, 2].includes(state.status) &&
                        <>
                            <FaCircle size={15} color="var(--success)" />
                            &nbsp;&nbsp;Pagamento efetuado em {formatDate({ value: new Date(`${state.receipt_date}T00:00:00`), location: 'Br', format: 'Date' })}
                        </>
                        }
                    </div>
                    <div className="data-box">
                        <h6 className="title-box">Dados da fatura - {state.number}</h6>
                        <Row>
                        <Col md={4} sm={12} xs={12}>
                            <FormGroup>
                            <Label className="text-secondary">Valor {[1, 2].includes(state.status) ? 'pago' : ''}</Label>
                            <h3 className={(state.status === 0 && state.days_due_date < 0) ? 'text-danger' : ([1, 2].includes(state.status) ? 'text-success' : 'text-focus')}>{formatValue({ value: state.value, currency: 'BRL' })}</h3>
                            {
                                (state.installment && state.installment.quantity > 1 && state.type_payment === 2) &&
                                <span>Parcelado em {state.installment.quantity}x de {state.installment.charges[0]?.pjbank ? formatValue({ value: state.installment.charges[0].pjbank.installments[0].value, currency: 'BRL' }) : formatValue({ value: currency(state.installment.value).divide(state.installment.quantity), currency: 'BRL' })}</span>
                            }
                            </FormGroup>
                        </Col>
                        {
                            (state.discount && state.discount > 0 && state.discount_date >= dateNow(null, 'YYYY-MM-DD')) ?
                            <Col md={4} sm={12} xs={12}>
                            <FormGroup>
                                <Label className="text-secondary">Valor com desconto</Label>
                                <h3 className="text-focus">{formatValue({ value: state.value_with_discount, currency: 'BRL' })}</h3>
                                <small>(até {formatDate({ value: new Date(`${state.discount_date}T00:00:00`), location: 'Br', format: 'Date' })})</small>
                            </FormGroup>
                            </Col>
                            :
                            ''
                        }
                        <Col md={4} sm={12} xs={12}>
                            <FormGroup>
                            <Label className="text-secondary">Data de vencimento</Label>
                            <h3 className={(state.status === 0 && state.days_due_date < 0) ? 'text-danger' : (state.status === 0 ? 'text-focus' : 'text-secondary')}>{formatDate({ value: new Date(`${state.due_date}T00:00:00`), location: 'Br', format: 'Date' })}</h3>
                            {
                                state.status === 0 &&
                                <small>{state.days_due_date < 0 ? `(vencido há ${state.days_due_date * -1} dias)` : (state.days_due_date > 1 ? `(daqui há ${state.days_due_date} dias)` : (state.days_due_date === 1 ? 'amanhã' : 'hoje'))}</small>
                            }
                            </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                        <Col md={12}>
                            <FormGroup>
                            <Label className="text-secondary">Descrição</Label>
                            <p>{state.description || 'Seu fornecedor não informou uma descrição para este serviço/produto'}</p>
                            </FormGroup>
                        </Col>
                        {
                            state.installment &&
                            <Col md={12}>
                            <FormGroup>
                                <Label className="text-secondary">Parcela</Label>
                                <p>
                                    {state.type_payment === 2
                                    ?
                                        `Parcelado em ${state.quantity}x vez(es)`
                                    :
                                        `${state.parcel_number} de ${state.installment.charges.length}`
                                    }
                                </p>
                            </FormGroup>
                            </Col>
                        }
                        </Row>
                    </div>
                    <div className="data-box">
                        <h6 className="title-box">Dados do comprador</h6>
                        <Row>
                        <Col md={12}>
                            <FormGroup>
                            <Label className="text-secondary">Nome:</Label>
                            <p>{state.customer ? `${state.customer.name} ${formatCpfCnpj({ value: state.customer.cpfcnpj })}` : ''}</p>
                            </FormGroup>
                        </Col>
                        {
                            (state.customer && state.customer.email) &&
                            <Col md={12}>
                            <FormGroup>
                                <Label className="text-secondary">E-mail:</Label>
                                <p>{state.customer.email}</p>
                            </FormGroup>
                            </Col>
                        }
                        </Row>
                    </div>
                    {
                        state.installment &&
                        (state.status === 1 && state.payment_by === 2) &&
                        <div className="data-box">
                        <h6 className="title-box">Resumo do parcelamento</h6>
                        <Row>
                            <Col md={12}>
                            <ul className="list-box">
                                {
                                    state.installment.type_payment === 2 && state.installment?.charges[0]?.pjbank ?
                                        state.installment.charges[0].pjbank.installments.map((installment, key) => (
                                            <li className="item-list-box">
                                                <span className={'is-payment-of-invoice'}>
                                                    <div className={`item-list-payment-link`}>Parcela {installment.installment_number} - <span className='text-focus'><b>{formatValue({ value: installment.value, currency: 'BRL' })}</b></span></div>
                                                </span>
                                            </li>
                                        ))
                                    :
                                state.installment.charges.filter((_, key) => {
                                    return (state.show_all_installments || key <= 4);
                                }).map((charge, key) => {
                                    return <li key={key} className="item-list-box">
                                    <span className={charge.pub_identifier === id ? 'is-payment-of-invoice' : ''}>
                                    <div className="item-list-box-number">{key + 1}</div>
                                    {
                                        [1, 2].includes(charge.status) &&
                                        <>
                                            {
                                                charge.status === 1
                                                ?
                                                <FaRegCheckCircle size={15} color="var(--success)" />
                                                :
                                                <FaRegMoneyBillAlt size={15} color="var(--success)" />
                                            }
                                        </>
                                    }
                                    {
                                        (charge.status === 0 && dateNow(null, 'YYYY-MM-DD') <= charge.due_date) &&
                                        <FaRegClock size={15} color="var(--warning)" />
                                    }
                                    {
                                        (charge.status === 0 && dateNow(null, 'YYYY-MM-DD') > charge.due_date) &&
                                        <FaRegTimesCircle size={15} color="var(--danger)" />
                                    }
                                    <div className={`item-list-payment-link ${charge.status === 0 && dateNow(null, 'YYYY-MM-DD') > charge.due_date ? 'text-danger' : ''}`} onClick={charge.pub_identifier !== id ? () => handleCharge(charge.pub_identifier) : () => {}} style={{ cursor: charge.pub_identifier !== id ? 'pointer' : 'text' }}>{formatDate({ value: new Date(`${charge.due_date}T00:00:00`), location: 'Br', format: 'Date' })} - {formatValue({ value: charge.value, currency: 'BRL' })}</div>
                                    <FaCaretLeft className="item-list-box-arrow" color="var(--focus)" />
                                    </span>
                                </li>
                                })
                                }
                            </ul>
                            {
                                (!state.show_all_installments && state.installment.charges.length > 5) &&
                                <a href='javascript:;' onClick={() => setState({ ...state, show_all_installments: true })} className="text-focus">
                                <div className="w-100 d-flex align-items-center justify-content-center flex-column">
                                    <Button className="mb-2 mt-2" color="focus" style={{ height: 40, width: 40, borderRadius: 20 }}>
                                    <FaArrowDown />
                                    </Button>
                                    Ver detalhes do parcelamento
                                </div>
                                </a>
                            }
                            </Col>
                        </Row>
                        </div>
                    }
                    {
                        state.subscription_resume.length > 0 &&
                        <div className="data-box">
                        <h6 className="title-box">Resumo da assinatura</h6>
                        <Row>
                            <Col md={12}>
                            <ul className="list-box">
                                {
                                state.subscription_resume.filter((_, key) => {
                                    return (key <= 4);
                                }).map((charge, key) => {
                                    return <li key={key} className="item-list-box">
                                    <span className={charge.pub_identifier === id ? 'is-payment-of-invoice' : ''}>
                                    <div className="item-list-box-number">{key + 1}</div>
                                    {
                                        [1, 2].includes(charge.status) &&
                                        <FaRegCheckCircle size={18} color="var(--success)" />
                                    }
                                    {
                                        (charge.status === 0 && dateNow(null, 'YYYY-MM-DD') <= charge.due_date) &&
                                        <FaRegClock size={18} color="var(--warning)" />
                                    }
                                    {
                                        (charge.status === 0 && dateNow(null, 'YYYY-MM-DD') > charge.due_date) &&
                                        <FaRegTimesCircle size={18} color="var(--danger)" />
                                    }
                                    <div className={`item-list-payment-link ${charge.status === 0 && dateNow(null, 'YYYY-MM-DD') > charge.due_date ? 'text-danger' : ''}`} onClick={charge.pub_identifier !== id ? () => handleCharge(charge.pub_identifier) : () => {}} style={{ cursor: charge.pub_identifier !== id ? 'pointer' : 'text' }}>{formatDate({ value: new Date(`${charge.due_date}T00:00:00`), location: 'Br', format: 'Date' })} - {formatValue({ value: charge.value, currency: 'BRL' })}</div>
                                    <FaCaretLeft className="item-list-box-arrow" color="var(--focus)" />
                                    </span>
                                </li>
                                })
                                }
                            </ul>
                            </Col>
                        </Row>
                        </div>
                    }
                    <div className="data-box">
                        {
                        state.status === 0 &&
                        <>
                            {
                            state.pending_charge &&
                            <Alert color="info">
                                <span><b>Cobrança anterior aguardando pagamento</b></span>
                                <br />
                                <span>Identificamos que você possui outra cobrança que ainda não foi paga, visualize a fatura anterior para efeturar seu pagamento.</span>
                                <div>
                                <Button className="mt-2 mr-2" color="light" onClick={() => setState({ ...state, pending_charge: null })}>Pagar Fatura Atual</Button>
                                <Button className="mt-2 mr-2" color="focus" onClick={() => handleCharge(state.pending_charge, false)}>Pagar Fatura Anterior</Button>
                                </div>
                            </Alert>
                            }
                            {
                            !state.pending_charge &&
                            <>
                                <h6 className="title-box">Forma de pagamento</h6>
                                {
                                state.type_payment === 3 &&
                                <div className="payment-forms">
                                    <div>
                                        <Button color={state.payment_by === 1 ? 'focus' : 'light'} className="mb-2 mr-2" onClick={() => handleChange(1, 'payment_by')}><FaBarcode /> BOLETO BANCÁRIO</Button>
                                        <Button id={'creditButton'} disabled={state.company.credit_card_status !== 'aprovado'} color={state.payment_by === 2 ? 'focus' : 'light'} className="mb-2 mr-2" onClick={() => handleChange(2, 'payment_by')}><FaRegCreditCard /> CARTÃO DE CRÉDITO</Button>
                                        <Button color={(state.payment_by === 3 || state.payment_by === 4) ? 'focus' : 'light'} className="mb-2 mr-2" onClick={() => handleChange(4, 'payment_by')}><FaPix /> PIX</Button>
                                        {
                                            state.company.credit_card_status !== 'aprovado' &&
                                            <UncontrolledTooltip target='creditButton' placement='top'>
                                                Seu fornecedor não habilitou pagamentos com cartão de crédito.
                                            </UncontrolledTooltip>
                                        }
                                    </div>
                                    {
                                        state.payment_by === 1 && confirmAlert.active &&
                                        <div>
                                            <Alert color="warning" className='mt-2'>
                                                Você está prestes a alterar a forma de pagamento desta fatura, você não poderá alterar a forma de pagamento após a geração do boleto confirma esta ação?
                                            <br />
                                            <Button color={'success'} className="mt-2 mr-2" onClick={sendInvoiceBoletoRequest}>Confirmar</Button>
                                            <Button color={'danger'} className="mt-2 mr-2" onClick={() => handleChange(4, 'payment_by')}>Cancelar</Button>
                                            </Alert>
                                        </div>
                                    }
                                </div>
                                }
                                <div>
                                {
                                    !showInfoCustomer &&
                                    <>
                                    {
                                        state.payment_by === 1 &&
                                        <h6>Boleto bancário para pagamento</h6>
                                    }
                                    {
                                        state.payment_by === 2 &&
                                        <>
                                            <h6>Informe os dados do cartão para efetuar o pagamento</h6>
                                            <span>Bandeiras aceitas: </span>
                                            <br />
                                            {
                                                CardIcons.map((icon, key) => {
                                                return <img key={key} className='px-2' src={icon.src} alt={`card-icon-${key}`} style={{ width: 70, height: 70 }} />
                                                })
                                            }
                                        </>
                                    }
                                    {
                                        (state.payment_by === 4  || state.payment_by === 3)&&
                                        <h6>Pix para pagamento</h6>
                                    }
                                    <div className="divider" />
                                    <Row>
                                        {
                                        state.payment_by === 1 &&
                                        <Col md={6} sm={12} xs={12}>
                                            <div className="box-view-boleto">
                                            {
                                                (state.plug_boleto && ['EMITIDO', 'REGISTRADO'].includes(state?.plug_boleto?.situation)  && !boletoLoading) &&
                                                <>
                                                <div>
                                                    <img src="https://files-nfservice-development.s3.ca-central-1.amazonaws.com/imagens/boleto.jpg" alt="img-boleto" style={{ cursor: 'pointer' }} onClick={() => window.open(state.pdf_url)} />
                                                </div>
                                                <Button className="mt-3 mr-2" color="focus" onClick={() => window.open(state.pdf_url)}>Imprimir</Button>
                                                <CopyToClipboard onCopy={() => SuccessToast({ placeholder: 'Linha digitável copiada para área de transferência!' })} text={state.plug_boleto.digitable_line}>
                                                    <Button className="mt-3 mr-2" color="focus" onClick={() => {}}>Copiar Linha Digitável</Button>
                                                </CopyToClipboard>
                                                </>
                                            }
                                            {
                                                boletoLoading &&
                                                <div className='d-flex justify-content-center'>
                                                    <LoadingIcon isLoading={true} size={60} />
                                                </div>
                                            }
                                            {
                                                (!state.plug_boleto || !['EMITIDO', 'REGISTRADO'].includes(state?.plug_boleto?.situation)) && !boletoLoading &&
                                                <Alert color="danger">Boleto não disponível, aguarde alguns instantes ou entre em contato com seu fornecedor!</Alert>
                                            }
                                            </div>
                                        </Col>
                                        }
                                        {
                                        state.payment_by === 2 &&
                                        <Col md={6} sm={12} xs={12}>
                                            <div>
                                            <Row>
                                                <Col md={12}>
                                                <FormGroup>
                                                    <CardInputToken
                                                        invalid={validation.card_number}
                                                        mask={cardsMasks(state.card.number)}
                                                        credencial={state.company?.pjbank_credit_card_credential?.credencial}
                                                        sandbox={window.location.href.includes('dev') || window.location.href.includes('sandbox') ? true : false}
                                                        maskChar={null}
                                                        placeholder="Número do cartão"
                                                        value={state.card.number}
                                                        onChange={({ target: { value } }) => handleChangeCard(value, 'number')}
                                                        onBlur={() => handleValidate(3, 'card_number')}
                                                        dataCallback={({ token, bandeira }) => setState({ ...state, card: { ...state.card, token, bandeira } })}
                                                    />
                                                    <FormFeedback>Informe um número de cartão válido</FormFeedback>
                                                </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                <FormGroup>
                                                    <Input invalid={validation.card_name} type="text" className="form-control" placeholder="Nome do titular do cartão" value={state.card.name} onChange={({ target: { value } }) => handleChangeCard(value, 'name')} onBlur={() => handleValidate(3, 'card_name')} />
                                                    <FormFeedback>Informe um nome válido</FormFeedback>
                                                </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                <FormGroup>
                                                    <InputMask invalid={validation.card_cvv} mask="9999" maskChar={null} placeholder="Código de segurança" value={state.card.cvv} onChange={({ target: { value } }) => handleChangeCard(value, 'cvv')} onBlur={() => handleValidate(3, 'card_cvv')} />
                                                    <FormFeedback>Informe um código válido</FormFeedback>
                                                </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                <p>Válido até:</p>
                                                </Col>
                                                <Col md={6} sm={6} xs={12}>
                                                <FormGroup>
                                                    <InputMask invalid={validation.card_month} mask="99" maskChar={null} placeholder="Mês" value={state.card.month} onChange={({ target: { value } }) => handleChangeCard(value, 'month')} onBlur={() => handleValidate(3, 'card_month')} />
                                                    <FormFeedback>Informe um mês válido</FormFeedback>
                                                </FormGroup>
                                                </Col>
                                                <Col md={6} sm={6} xs={12}>
                                                <FormGroup>
                                                    <InputMask invalid={validation.card_year} mask="9999" maskChar={null} placeholder="Ano" value={state.card.year} onChange={({ target: { value } }) => handleChangeCard(value, 'year')} onBlur={() => handleValidate(3, 'card_year')} />
                                                    <FormFeedback>Informe um ano válido</FormFeedback>
                                                </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Button className='btn btn-block' color="focus" onClick={handleShowInfoCustomer}>Continuar</Button>
                                                </Col>
                                            </Row>
                                            </div>
                                        </Col>
                                        }
                                        {
                                        (state.payment_by === 4 || state.payment_by === 3) &&
                                        <Col md={6} sm={12} xs={12} className='d-flex justify-content-center'>
                                            <div className="box-view-boleto">
                                            {
                                                (state.pix && ['ATIVA'].includes(state.pix.situation)) &&
                                                <>
                                                <div>
                                                    <img src={state.pix.qrcode} alt="img-pix" />
                                                </div>
                                                <CopyToClipboard onCopy={() => SuccessToast({ placeholder: 'Linha digitável copiada para área de transferência!' })} text={state.pix.copiaecola}>
                                                    <Button className="mt-3 mr-2" color="focus" onClick={() => {}}>Copiar Pix Copia e Cola</Button>
                                                </CopyToClipboard>
                                                </>
                                            }
                                            {
                                                (!state.pix || ['REMOVIDA_PELO_USUARIO_RECEBEDOR', 'REMOVIDA_PELO_PSP'].includes(state.pix.situation)) &&
                                                <Alert color="danger">Pix não disponível, aguarde alguns instantes ou entre em contato com seu fornecedor!</Alert>
                                            }
                                            </div>
                                        </Col>
                                        }
                                        <Col md={6} sm={12} xs={12} className="box-security-website notMobile"></Col>
                                    </Row>
                                    </>
                                }
                                {
                                    showInfoCustomer &&
                                    <>
                                    <div>
                                        <h6>Informações do titular do cartão</h6>
                                        <div className="divider" />
                                        <Row>
                                        <Col md={6} sm={12} xs={12}>
                                            <FormGroup>
                                            <Input invalid={validation.customer_name} type="text" placeholder="Nome completo" value={state.customer.name} onChange={({ target: { value } }) => handleChangeCustomer(value, 'name')} onBlur={() => handleValidate(3, 'customer_name')} />
                                            <FormFeedback>Informe um nome válido</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12} xs={12}>
                                            <FormGroup>
                                            <Input invalid={validation.customer_email} type="text" placeholder="E-mail" value={state.customer.email} onChange={({ target: { value } }) => handleChangeCustomer(value, 'email')} onBlur={() => handleValidate(3, 'customer_email')} />
                                            <FormFeedback>Informe um e-mail válido</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12} xs={12}>
                                            <FormGroup>
                                            <Input id='CustomerCpfCnpjDisabled' invalid={validation.customer_cpfcnpj} type="text" placeholder="CPF ou CNPJ" value={formatCpfCnpj({ value: state.customer.cpfcnpj })} onChange={({ target: { value } }) => handleChangeCustomer(value, 'cpfcnpj')} onBlur={() => handleValidate(3, 'customer_cpfcnpj')} disabled />
                                            <FormFeedback>Informe um CPF/CNPJ válido</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12} xs={12}>
                                            <FormGroup>
                                            <InputMask mask="(99) 9999-9999" placeholder="Telefone (opcional)" maskChar={null} value={state.customer.phone} onChange={({ target: { value } }) => handleChangeCustomer(value, 'phone')} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12} xs={12}>
                                            <FormGroup>
                                            <InputMask invalid={validation.customer_cellphone} mask="(99) 99999-9999" placeholder="Celular" maskChar={null} value={state.customer.cellphone} onChange={({ target: { value } }) => handleChangeCustomer(value, 'cellphone')} onBlur={() => handleValidate(3, 'customer_cellphone')} />
                                            <FormFeedback>Informe um celular válido</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        </Row>
                                        <h6>Informações de endereço</h6>
                                        <div className="divider" />
                                        <Row>
                                        <Col md={6} sm={12} xs={12}>
                                            <FormGroup>
                                                <InputMask invalid={validation.customer_cep} mask="99999-999" placeholder="CEP" maskChar={null} value={state.customer.cep} onChange={({ target: { value } }) => handleChangeCustomer(value, 'cep')} onBlur={() => handleValidate(3, 'customer_cep')} />
                                                <FormFeedback>Informe um CEP válido</FormFeedback>
                                            </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <Col md={6} sm={12} xs={12}>
                                            <FormGroup>
                                            <Input invalid={validation.customer_address} type="text" placeholder="Logradouro" value={state.customer.address} onChange={({ target: { value } }) => handleChangeCustomer(value, 'address')} onBlur={() => handleValidate(3, 'customer_address')} />
                                            <FormFeedback>Informe um logradouro válido</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3} sm={12} xs={12}>
                                            <FormGroup>
                                            <Input invalid={validation.customer_number} type="text" placeholder="Número" maxLength={10} value={state.customer.number} onChange={({ target: { value } }) => handleChangeCustomer(value, 'number')} onBlur={() => handleValidate(3, 'customer_number')} />
                                            <FormFeedback>Informe um número válido</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12} xs={12}>
                                            <FormGroup>
                                            <Input invalid={validation.customer_neighborhood} type="text" placeholder="Bairro" value={state.customer.neighborhood} onChange={({ target: { value } }) => handleChangeCustomer(value, 'neighborhood')} onBlur={() => handleValidate(3, 'customer_neighborhood')} />
                                            <FormFeedback>Informe um bairro válido</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12} xs={12}>
                                            <FormGroup>
                                            <Input invalid={validation.customer_complement} type="text" placeholder="Complemento" value={state.customer.complement} onChange={({ target: { value } }) => handleChangeCustomer(value, 'complement')} onBlur={() => handleValidate(3, 'customer_complement')} />
                                            <FormFeedback>O complemento pode ter no máximo 100 caracteres</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12} xs={12}>
                                            <FormGroup>
                                            <AutoComplete invalid={validation.customer_state} invalidMsg="Selecione um estado válido" urlFind="states" optionLabel="sigla" placeholder="Selecione o estado..." defaultOptions={true} value={state.customer.state} onChange={value => handleChangeCustomer(value, 'state')} onBlur={() => handleValidate(3, 'customer_state')} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12} xs={12}>
                                            <FormGroup>
                                            <AutoComplete invalid={validation.customer_city} invalidMsg="Selecione uma cidade válida" urlFind="cities" placeholder="Selecione a cidade..." defaultOptions={true} value={state.customer.city} onChange={value => handleChangeCustomer(value, 'city')} onBlur={() => handleValidate(3, 'customer_city')} aditionalFilters={{
                                                state_id: state.customer.state ? state.customer.state.value : ''
                                            }} />
                                            </FormGroup>
                                        </Col>
                                        </Row>
                                        <p>* Ao clicar no botão CONFIRMAR abaixo, confirmo que as informações acima são minhas e me responsabilizo por quaisquer divergências.</p>
                                        <Button className="mt-2 mr-2" color="light" onClick={() => setShowInfoCustomer(!showInfoCustomer)} disabled={inRequest}>Voltar</Button>
                                        <Button className="mt-2 mr-2" color="focus" onClick={handleSubmitCard} disabled={inRequest}>{inRequest ? <LoadingIcon size={20} color="var(--white)" title="Carregando..." isLoading={true} /> : 'Confirmar'}</Button>
                                    </div>
                                    <UncontrolledTooltip placement="top" target="CustomerCpfCnpjDisabled">CPF/CNPJ não pode ser alterado, entre em contato com seu fornecedor para o mesmo fazer a atualização</UncontrolledTooltip>
                                    </>
                                }
                                </div>
                            </>
                            }
                        </>
                        }
                        {
                        [1, 2].includes(state.status) &&
                        <span>O pagamento desta fatura já foi efetuado.</span>
                        }
                    </div>
                    <div style={{ padding: 16 }}>
                        Esta cobrança é de responsabilidade única e exclusiva de <strong>{state.company.name}</strong> {state.company && state.company.type === 1 ? 'CPF' : 'CNPJ'} <strong>{formatCpfCnpj({ value: state.company.cpfcnpj })}</strong> e o WFPAY não se responsabiliza pelo produto e/ou serviço prestado, em caso de dúvida entre em contrato com seu fornecedor
                    </div>
                    <div className="divider" />
                    <div style={{ padding: 16 }}>
                        Esta fatura é intermediada por <a href="/">wfpay.com.br</a>
                    </div>
                    </div>
                </div>
              }
            </div>
            {
              !isMobile() &&
              <div className="text-center opacity-8 mt-3">
                Copyright &copy; WFPay Gestão Financeira {dateNow(null, 'YYYY')}
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  loading: state.AppConfig.loading,
})

const mapDispatchToProps = (dispatch) => ({
  setLoading: (enable, text = null) => dispatch(setLoading(enable, text))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChargePub);
